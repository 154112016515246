import React from "react";

const LegalEnforcement = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Legal-Enforcement">LEGAL ENFORCEMENT</h1>
      <span></span>
      <p>
        When dealing with contracts based off of tokens representing regulated
        currencies it is important to have a clearly defined legal structure.
        Consequently, BASElayer takes the approach that all contracts and tokens
        on the network inherit the legal implications and attributes of their
        physical world counterparts.
      </p>
    </div>
  );
};

const LegalityOfSmartContracts = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Legality-of-Smart-Contracts">Legality of Smart Contracts</h1>
      <span>The standing of smart contracts in the eyes of the law</span>
      <p>
        As of this point in time there are no federal laws concerning smart
        contracts. Rather, enforceability and interpretation are conducted at
        the state level. While in most cases state legislation seems to be
        harmonized, due to the financial subject matter of the network,
        BASElayer seeks to clear up any uncertainty.{" "}
      </p>
      <p>
        All contracts issued on the BASElayer platform are legally binding
        unless explicitly stated otherwise. All tokenized assets are legally
        bound to the assets they are designed to represent and should be treated
        as such in the eyes of the law.
      </p>
    </div>
  );
};
const CCCRegulatoryCompliance = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="CCC-Regulatory-Complianc">CCC Regulatory Compliance</h1>
      <span>Core Currency Custodians regulatory requirements</span>
      <p>
        The requirements for a TAC are entirely up CCCs are required to comply
        with local regulations and reporting to the appropriate agencies.
        Regulations vary by country and state but may include the reporting and
        compliance requirements describes bellow.
      </p>
      <ul>
        <li>
          <p>
            <b>
              Anti-Money Laundering (AML) and Combating the Financing of
              Terrorism (CFT)
            </b>{" "}
            – Money laundering is the process of moving illegal funds by
            disguising the source, nature or ownership so that funds can be
            legally accessed or distributed via legitimate financial channels
            and credible institutions. In short, it is converting “dirty money”
            into “clean money.” Anti-Money Laundering (AML) refers to the laws
            and procedures designed to stop money laundering from occurring.{" "}
          </p>
          <p>
            Terrorist financing is the solicitation, collection, or provision of
            funds to organizations engaged in terrorist activity or
            organizations that support terrorism and its proliferation.
            Combating the Financing of Terrorism (CFT) refers to the process of
            identifying, reporting, and blocking flows of funds used to finance
            terrorism. Learn more here and here.
          </p>
        </li>
        <li>
          <p>
            <b>Fee Disclosure and Trancing Funds</b> – In the United States,
            Dodd Frank 1073 Electronic Fund Transfer Act (Regulation E) requires
            banks to provide information on cost and delivery terms for
            international payments originating in the US including exchange
            rate, fees, and the amount to be received by the designated
            recipient in the foreign country. "Pre-payment disclosure" is
            provided to a consumer when requesting an international electronic
            payment and “receipt disclosure” is provided to a consumer at the
            time consumer authorizes the transfer. Learn more here.{" "}
          </p>
          <p>
            In the European Union, EU Funds Transfer Regulation requires that
            the originator’s bank, the beneficiary’s bank, and any intermediary
            banks include certain details of the payer and payee in transaction
            details to detect, investigate, and prevent money laundering and
            terrorist financing. Learn more here and here.
          </p>
        </li>
        <li>
          <b>Guidance on Virtual Currency and Money Service Business</b> –Below
          is a list of the most recent regulatory requirements for business
          dealing in tokenized assets.{" "}
          <ul>
            •{" "}
            <li>
              FinCEN Guidance and Definitions around Virtual Currency, March 18,
              2013
            </li>
            •{" "}
            <li>
              FinCEN Publishes Two Rulings on Virtual Currency Miners and
              Investors, January 30, 2014
            </li>
            •{" "}
            <li>
              European Banking Authority Opinion on Virtual Currencies, July 4,
              2014
            </li>
            • <li>Financial Action Task Force, July 2009</li>
          </ul>
        </li>
        <li>
          <b>Know Your Customer (KYC)</b> – Know Your Customer (KYC) refers to
          due diligence activities conducted by a financial institution to
          determine and verify the identity of its customers to prevent use of
          the institution for criminal activity. Criminal activity in financial
          terms may include money laundering, terrorist financing, financial
          fraud, and identity theft. Customers may be individuals,
          intermediaries, or businesses.
          <ul>
            • <li>The KYC process generally aims to: </li>•{" "}
            <li>
              Identify the customer (and, in the case of organizations and
              businesses, any beneficial owners){" "}
            </li>
            •{" "}
            <li>
              Understand the purpose and intended nature of the business
              relationship
            </li>
            • <li>Understand the expected transaction activity. </li>
          </ul>
          KYC is critical for financial institutions and related businesses to
          mitigate risk, especially legal and reputational risk. Having an
          inadequate or nonexistent KYC program may result in civil and criminal
          penalties for the institution or individual employees. Learn more here
          and here.{" "}
        </li>
        <li>
          <b>Office of Foreign Assets Control (OFAC)</b> – The Office of Foreign
          Assets Control (OFAC) is an agency of the US Department of Treasury
          that administers and enforces economic and trade sanctions in support
          of U.S. foreign policy and national security objectives. All U.S.
          persons and U.S. incorporated entities and their foreign branches must
          follow OFAC regulations. Under OFAC regulations, U.S. financial
          institutions are prohibited—unless authorized by OFAC or expressly
          exempted by statute—from conducting transactions and other dealings
          with individuals, entities, or countries under sanctions or embargo
          programs administered and enforced by OFAC.{" "}
        </li>
        <li>
          <p>
            Source of Funds – To prevent illicit funds from passing through
            their systems, financial institutions must be able to determine
            within reason if the source of a customer’s funds is linked to
            criminal activity.
          </p>
          <p>
            Determining the exact source of funds for every customer may not be
            administratively feasible. As a result, some regulatory authorities
            may not provide specific regulation or guidance for all accounts. In
            specific cases, however, authorities may require financial
            institutions to identify and report the source of funds. Guidance by
            the FATF recommends that where the risks of money laundering or
            terrorist financing are higher (commonly referred to as a
            “risk-based approach”), financial institutions conduct enhanced due
            diligence, including but not limited to determining the customer’s
            source of funds.
          </p>
        </li>
        <li>
          <b> Suspicious Activity Reporting</b> – If a financial institution
          suspects that funds may be related to criminal activity, the
          institution must file a Suspicious Activity Report (SAR) with the
          appropriate regulatory authority. Failure to report suspicious
          activity may result in in penalties for the institution. Learn more
          here and here.
        </li>
        <li>
          <b>Travel Rule</b> – The Travel Rule is a Bank Secrecy Act (BSA) rule
          requiring funds-transmitting financial institutions to forward certain
          information to the next financial institution if the funds transmittal
          equals or exceeds the USD equivalent of $3,000. The following
          information must be included in the transmittal order:
          <ul>
            <li>The name of the transmitter, </li>
            <li>The account number of the transmitter, if used, </li>
            <li>The address of the transmitter, </li>
            <li>The identity of the transmitter’s financial institution, </li>
            <li>The amount of the transmittal order, </li>
            <li>The execution date of the transmittal order, and</li>
            <li>The identity of the recipient's financial institution. </li>
          </ul>
          More information can be found here.
        </li>
      </ul>
    </div>
  );
};

export { LegalEnforcement, LegalityOfSmartContracts, CCCRegulatoryCompliance };
