import React from "react";

const App = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Introduction">Introduction</h1>
      <p>
        Tokenization is changing the way assets – both financial and
        nonfinancial - are owned and traded. There is no longer a need for third
        parties to act as agents or brokers. Through a distributed ledger system
        such as a blockchain, asset owners can take full control over their
        holdings without compromising security.
      </p>
      <p>
        Until now, creators of tokenized asset applications have been
        constrained by the limitations of existing development platforms. Unlike
        fixed platforms which allow interaction with only a limited set of
        assets, or platforms created for narrow-case applications such as
        cryptocurrencies, BASElayer provides developers with robust control over
        their token asset applications. Through direct programmability, the
        ability to create entirely new asset types on the platform, and other
        features detailed below, developers can achieve the full scope of their
        vision.
      </p>
      <p>
        BASElayer’s network is designed around real-world needs, providing three
        to five-second transactions (and settlement) with infinite scalability.
        As a result, regardless of how busy the network is, transaction times
        for all users are maintained. This performance is inherited by all
        applications built on the platform.
      </p>
      <p>
        {" "}
        In addition to fast and scalable transactions, BASElayer offers Turing
        complete, language agnostic smart contract creation. These contracts
        coalesce to form an intelligent network of contract, interacting with
        one another with immutable memory, contract replicability and without
        human intervention. Further, BASElayer’s scalability applies to its
        built-in virtual machine, meaning that there are no bottlenecks when it
        comes to processing contracts.
      </p>
      <p>
        {" "}
        To support this robust network, BASElayer employs a unique distributed
        ledger architecture. In the following sections we will examine all the
        components of this architecture and how they coalesce into an incredibly
        efficient payment network.
      </p>
    </div>
  );
};

export default App;

//</p><p>
//<br/>
