import React from "react";

import Item from "./Item";

const App = (props) => {
  const chain = props.cont ? props.cont : [];
  return (
    <React.Fragment>
      {chain.map((section) => (
        <Item section={section} />
      ))}
    </React.Fragment>
  );
};

export default App;
