import React from "react";

const Network = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Network">NETWORK</h1>
      <span></span>
      <p>
        BASElayer’s network is designed around three key principals: Speed,
        Scalability, and Security. It is meticulously engineered to adhere to
        all three of these principals at all time. Users are never forced to
        choose between one or the others. This can partially be attributed to
        the horizontal scalability of the blockchains which drive the network,
        but also to the carefully chosen consensus mechanism. The final product
        is a payment network with 3-5 second settlement, no central point of
        attack, and microscopic fees.
      </p>
    </div>
  );
};
const NetworkFees = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Network-Fees">Network Fees</h1>
      <span>How fees are utilized on the BASElayer network</span>
      <p>
        BASElayer uses transaction fees as a way of deterring network spam and
        funding the central nodes. Fees are designed to make any transaction, no
        matter the size, inexpensive.
      </p>
      <h2>Fee Structure</h2>
      <p>
        {" "}
        BASElayer’s fee structure is designed to accommodate both small and
        large transactions. In fact, a user can economically transact $0.01 and
        $1,000,000 over the same network. This is a feature that is impossible
        to accomplish with traditional payment systems.{" "}
      </p>
      <p>
        All core currencies adhere to the same default 0.01% fee. This fee is
        capped at a value of $200 (0.01% of $20,000) to both incentivize the
        bundling of larger transactions (minimizing network traffic) and keep
        larger transactions inexpensive. .
      </p>
      <ul>
        <li id="Tokenized-Assets/Non-Core-Currencies">
          <b>Tokenized Assets/Non-Core Currencies</b> – All assets other than
          the core currencies traded on the BASElayer platform are subject to
          the same, capped 0.01% fee as the core currencies. However, developers
          of their own tokenized assets can choose to add additional fees to
          their tokens. The fee can either be on a fixed or percentage basis and
          must be specified in the Mint Contract that spawns the token. While
          the decision is ultimately up to the author, we encourage token
          creators to implement additional fees only when there are potential
          background costs necessary to support the token in question. An
          example of this would be a broker’s fee on a tokenized security. The
          security token could be accompanied with a flat exchange fee made
          payable to the listing exchange.
        </li>
        <li id="Contract-Fees">
          <b>Contract Fees</b> – Smart Contracts on the BASElayer platform are
          also encouraged to implement a small fee. This is a form of spam
          prevention. A smart contract’s fee can be set to whatever value the
          author desires. The receiving address of the fee can also be
          determined by the author. These values are set at the inception of the
          contract and can only be changed by the author or addresses that the
          author grants permission to (using signatures).
        </li>
      </ul>

      <h2 id="Adaptive-Fees">Adaptive Fees </h2>
      <p>
        {" "}
        BASElayer uses a traffic dependent fee multiplier to deter excessive
        spamming. However, the network employs multiple sidechains to moderate
        traffic and increasing the transaction fee is extremely uncommon. It is
        a last resort and is only used to halt a massive DOS attack.{" "}
      </p>
      <p>
        To understand this concept better, it’s best to think of the creation of
        a block in the blockchain as a mini auction. A transaction’s fee is a
        “bid” to enter a transaction into the next block. Therefore, a
        transaction offering a higher fee, or bid, is accepted into the next
        block over a transaction with a smaller fee.{" "}
      </p>
      <p>
        The value of the bid is taken in relation to the size of the
        transaction. For example, a 0.01% fee on a million-dollar transaction is
        larger in dollar terms than a 0.1% fee on a 10-dollar transaction, but
        the 0.1% fee is deemed more valuable by the network because the user is
        offering a larger percentage of the transaction. In the event that the
        network is overloaded, and the fee increased, transactions bidding a
        higher percentage are entered into the next block before other
        transactions. Any transactions implemented at the original fee remain
        pending until traffic returns to normal and the fees revert to their
        default.{" "}
      </p>
      <p>
        It is important to note that all ties in fee percentages go to the older
        transaction and that the fee cap is not variable.
      </p>
      <h2>Fee Destination </h2>
      <p>
        {" "}
        BASElayer Core collects network fees in order to fund operations of the
        network and incentivize node holders.
      </p>
    </div>
  );
};
const Nodes = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Nodes">Nodes</h1>
      <span>The node structure of the BASElayer network</span>
      <p>
        Nodes are the workhorses of any blockchain network. It is their job to
        establish consensus and process smart contracts. On the BASElayer
        network, there are two types of nodes, Trusted Nodes and Peer Nodes. It
        is easier to think of the former as Nodes, as they do the bulk of the
        work, and the latter as Peers, as they have one simple task. From here
        on out, the term Node will refer to Trusted Nodes and the term Peer will
        refer to Peer Nodes.{" "}
      </p>
      <h2 id="Trusted-Nodes-(Nodes)">Trusted Nodes (Nodes) </h2>
      <p>
        {" "}
        BASElayer uses a system of trusted nodes referred to, simply, as
        ‘nodes.’ Nodes are servers running the BASElayer Node Software. All
        transactions and contracts are processed by these servers. To ensure
        that the network as a whole is capable of meeting performance standers
        all nodes must meet a specified set of hardware requirements laid out by
        BASElayer Core. Requirements are established to ensure that transaction
        times are equal to or less than the industry standard of seven seconds.
        At random intervals, randomly selected nodes throughout the network will
        be “diagnosed” to ensure that the performance requirements are met. If a
        node does not pass the requirements it will be dropped from the network
        until it can again pass the diagnostic.{" "}
      </p>
      <p>
        To ensure that the network is capable of scaling processing power
        without forfeiting security, nodes require authorization from BASElayer
        Core before they can join the network. This ensures that no malicious
        actors have the ability process their own contracts. While a
        verification process for nodes may be interpreted as a centralizing
        component, it is important to note that BASElayer Core has no ability to
        influence a verified node’s activity. The verification process is only
        implemented to safeguard the network from malicious node spamming. For
        more information on this concept please see the runtime environment
        section of this paper.{" "}
      </p>
      <p>
        Custodians wishing the join the network are also required to host a
        node. This both grows the network and ensures the network’s high
        standards are upheld.{" "}
      </p>
      <p>
        The more nodes that are members of the network, the stronger, and more
        powerful, the network. Therefore, to incentivize the creation of nodes,
        node owners are paid a portion of the transaction fees collected by the
        network. Once a month, fifty percent of all transactions and processing
        fees are divided amongst and paid to node holders (more specifically the
        address pared with the node) Node payments also help incentivize
        custodians to join the BASElayer platform, bolstering the networks
        ecosystem.{" "}
      </p>
      <h2 id="Peer-Nodes-(Peers)">Peer Nodes (Peers) </h2>
      <p>
        {" "}
        Peer Nodes, referred to as Peers, hold copies of the BASElayer
        blockchain. They do not have any hardware requirements (other than an
        internet connection) and can be run from any device. The purpose of a
        Peer is to increase the number of copies of the blockchain,
        strengthening the network’s security, and to increase blockchain
        download times, acting as seeds for a torrent . Peers do not participate
        in consensus or contract processing. They act passively as a network
        stenographer.{" "}
      </p>
      <p>
        Unlike Nodes, Peers do not receive a payout from the network. Full
        wallets (as opposed to light wallets) require a full copy of the
        blockchain to be downloaded and updated becoming peers in the process.
        Complex applications built on the BASElayer platform will inherently act
        as peers.
      </p>
    </div>
  );
};
const Consensus = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Consensus">Consensus</h1>
      <span>
        How the nodes on the BASElayer network establish consensus and
        transaction validation
      </span>
      <p>
        Many blockchain networks use proof of work (POW) or proof of stake (POS)
        to solve the double spend problem. While both of these solutions have
        their merits, neither one is ideal for a platform where multiple assets,
        with different exchange rates, are transacted.{" "}
      </p>
      <p>
        On traditional blockchains, when a block is published, the person that
        mines or validates it has complete control over what transactions go
        into that block. In single currency platforms, that’s fine. If that
        miner or validator does not include a specific transaction for some
        reason, the next one will.{" "}
      </p>
      <p>
        But on a multi-asset platform like BASElayer, this would cause a lot of
        problems. Imagine if someone had the ability to choose which
        transactions occurred in a cross-currency system with fluctuating
        exchange rates. A malicious miner or validator could take the very best
        offers for himself. If someone placed an order to exchange US Dollars
        for Euros and the exchange rate changed after the order was submitted,
        the malicious actor could block the transaction from the current block
        and fill the order in the next block at a more favorable rate to
        himself.{" "}
      </p>
      <p>
        In POW and POS networks where assets have different exchange rates,
        people are incentivized to gain mining power or validator status, not to
        receive transaction fees, but to exploit the system. Furthermore, the
        POS model breaks down on a fundamental level when dealing with physical
        assets because the value of the asset is not dependent on the security
        of the network. As a result, staking tokens does not deter malicious
        actors from trying to break the network as there are no financial
        repercussions.{" "}
      </p>
      <p>
        BASElayer solves these problems through the use of a much faster, more
        efficient, and multi-asset friendly consensus protocol. Using a modified
        version of the Ripple© Protocol Consensus Algorithm developed by David
        Schwartz, Noah Youngs, and Arthur Britto, BASElayer is able to achieve
        three to five second transaction times without using exorbitant amounts
        of power or compromising the ability to support multiple assets.{" "}
      </p>
      <h2 id="Avalanche">Avalanche </h2>
      <p>
        {" "}
        Avalanche consists of two parts: a consensus phase where the nodes vet
        and agree upon a set of transactions to be processed and a validation
        phase where nodes record the results of the transactions into an
        immutable ledger.{" "}
      </p>
      <p>
        While the entire process of agreeing on and validating transactions is
        commonly referred to as consensus, it is really only the first step in
        the twostep process. In its most basic form, consensus is a solution to
        the double spend problem. It does this by grouping transactions into
        ‘sets’ that can then be processed as a unit. The transactions within the
        set are ordered by fee size.{" "}
      </p>
      <p>
        A supermajority of the network’s nodes must then agree on which
        transactions to include in the set through voting. Each node looks at
        the nodes that it directly trusts, and it defers to their position. If
        the majority of those trusted nodes agree on a transaction, then it will
        agree too. This is where the term Avalanche comes from. As the final
        decision becomes more heavily agreed upon, its certainty grows
        exponentially. Once a critical mass (roughly 80%) of the network has
        agreed upon a transaction, it is included in the set.{" "}
      </p>
      <p>
        Please note: In the event an honest transaction is not agreed upon
        through consensus (maybe it was issued too late in the process) then it
        will be added to the front of the list in the next round of consensus.{" "}
      </p>
      <p>
        The second phase of Avalanche is the validation phase. Once all the
        nodes have realized that there has been a consensus, they build the next
        block using the agreed upon set of transactions, then they publish the
        validated block to the chain. This is a signed statement that all the
        nodes have agreed on this decision.{" "}
      </p>
      <h2 id="Transaction-Process">Transaction Process </h2>
      <p>
        {" "}
        To help make this process easier to understand, lets follow a
        transaction through the network from its inception to its confirmation.{" "}
      </p>
      <p>
        A transaction is initiated when a user posts a request to the nearest
        node to transfer money. When a node receives a transaction, it conducts
        a light vetting to see whether the transaction appears valid. If a
        transaction is obviously invalid, then there is no point in wasting the
        networks collective processing power on it. If the transaction is deemed
        by the node to be valid, the node then relays the transaction to other
        nodes. Each node preforms a validity test until it has spread to all the
        nodes on the network.{" "}
      </p>
      <p>
        Then a consensus round begins. Consensus rounds can begin two different
        ways. One, there is a timer that is triggered when a preceding consensus
        round closes which lasts roughly 10 seconds (it is very rare that this
        10 second limit is reached as the nodes come to an agreement much
        sooner) or two, there is an unconfirmed transaction proposed to an empty
        consensus round.{" "}
      </p>
      <p>
        The proposal will then cascade throughout the network and, at some
        point, the majority will agree on a proposed consensus set (group of
        transactions) that includes the transaction in question. At this point
        there is an agreement and the transactions in the set are executed.{" "}
      </p>
      <p>
        Once executed, a ledger is built with the transactions (and their
        results). The nodes then begin publishing validations of this ledger.
        They send out a signed statement that says, “I’m a validator and I built
        this ledger, and it includes this transaction.”{" "}
      </p>
      <p>
        Once those validations are spread around the network, nodes that see a
        supermajority of validations consider the ledger confirmed and therefore
        the transaction is confirmed. This makes conflicting transactions
        forever invalid.{" "}
      </p>
      <h2 id="Block-Time/Transaction-Speed">Block Time/Transaction Speed </h2>
      <p>
        {" "}
        When dealing with network performance, transaction speed (also known as
        block time) and transactions per second (TPS) are often confused. The
        former is a measurement of how long it takes a single transaction to be
        confirmed. Think of it as the amount of time you have to wait for your
        transaction to be approved. The latter is a measure of network
        throughput/scalability. TPS is the quantity of transactions a network
        can include in a block divided by the block time. It is a measure of
        capacity and not speed.{" "}
      </p>
      <p>
        These metrics are often seen as mutually exclusive. While some platforms
        provide TPS statistics that rival the likes of Visa, they have block
        times between 1 to 45 minutes which far exceeds the seven second payment
        approval cap required by merchant services. These platforms are
        subsequently not suitable for every day purchases.{" "}
      </p>
      <p>
        On the other hand, BASElayer’s unique architecture both maximizes TPS
        and minimizes Block Time. While we will discuss throughput later in this
        paper, fast transaction speeds are made possible by the consensus
        protocol. Transactions, including settlement, take three to five
        seconds. To put this another way, blocks are created every three to five
        seconds. This lightning fast block time was engineered so that BASElayer
        could function as a payment network.{" "}
      </p>
      <p>
        You can read more about how network throughput is maximized in the
        Cross-chain Communication section of this paper.
      </p>
    </div>
  );
};
const BlockStructure = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Block-Structure">Block Structure</h1>
      <span>The layout of a block in the BASElayer blockchain</span>
      <p>
        A blockchain is, in its most basic form, a data structure. Therefore,
        when dealing with platforms as complex as BASElayer’s, it is important
        to use a clear and concise ledger format, also referred to as a block
        structure. In the case of BASElayer, this block structure has been
        altered to allow for faster transaction times. Because BASElayer uses a
        system of trusted nodes, blocks do not need to be strung together using
        hashes in quite the same way Bitcoin or Ethereum is. Blocks only need to
        know the hash of the previous block. This is made possible by the
        “Finality” of verification and the non-necessity for a ’longest chain’
        rule. Furthermore, the identifying hash uniquely identifies the contents
        of that block.{" "}
      </p>
      <p>
        After a round of consensus is completed and a set of transactions is
        verified, the set is referred to as a block. Each block has a standard
        structure. A well-designed block structure makes searching through the
        blockchain for relevant data quick and easy.{" "}
      </p>
      <p>
        Each block consists of two parts, a header and a body. The block header
        provides information regarding the blocks content. The header’s purpose
        is to streamline the search process. A query can examine the header for
        all relevant data to locate the information it is searching for without
        having to parse through the entirety of each block. The body contains
        the contents of each block.{" "}
      </p>
      <h2 id="Block-Header">Block Header </h2>
      <p>
        The block header specifies the sizes, in bytes, of the block body’s
        components. This allows for searches to skip to the location in the
        block with information relative to its query. The header also contains
        information regarding the block as it relates to the blockchain such as
        the block’s index number and hash.{" "}
      </p>
      <h2 id="Block-Body">Block Body </h2>
      <p>
        The block body includes all the information pertaining to the network.
        It is broken into three main categories; Certificates, Transactions, and
        Contracts. Unlike many blockchain technologies, each new block in a
        BASElayer chain contains the entirety of the current network state. This
        means you don't need to collect the entire history to know what's
        happening now. Nodes can therefore verify the validity of certificates
        and contracts much faster.{" "}
      </p>
      <p>
        To make sure that the current state of the network is always readily
        accessible, each of the three sections of the body are broken down into
        two subsections; Newly-applied data and State Data. The final result is
        a tree data structure as follows:{" "}
      </p>
      <ul>
        <li>
          <h3>Signatures</h3>
          <ul>
            <li>
              Newly-applied Signatures
              <ul>
                <li>Added Signatures</li>
                <li>Voided Signatures</li>
              </ul>
            </li>
            <li>Signature State Data</li>
          </ul>
        </li>
        <li>
          <h3>Transactions</h3>
          <ul>
            <li>Newly-applied Transactions</li>
            <li>Certificate State Data</li>
          </ul>
        </li>
        <li>
          <h3>Contracts </h3>
          <ul>
            <li>Newly-applied Contracts</li>
            <li>
              Certificate State Data
              <ul>
                <li>Voided Contracts</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export { Network, NetworkFees, Nodes, Consensus, BlockStructure };
