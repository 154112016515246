import React from "react";

const Custodians = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Key-Custodians">CUSTODIANS</h1>
      <span></span>
      <p>
        Custodians are the entry and exit points of the network. They hold the
        underlying asset of a token until it is redeemed. Custodians operate in
        much the same way a chip redemption kiosk at a casino does. A BASElayer
        user can use a custodian to redeem compatible tokens they have acquired
        on the network. There are two types of custodians, core currency
        custodians (CCC) and third-party asset custodians (TAC), corresponding
        with the core currency and third-party asset categories on the platform.
      </p>
    </div>
  );
};
const InterfacingWithBASElayer = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Interfacing-with-BASElayer">Interfacing with BASElayer</h1>
      <span>How custodian interact with the BASElayer platform</span>
      <p>
        Becoming a CCC or a TAC requires approval from BASElayer Core or a
        token’s issuer respectively. Approval is granted through a signature on
        the custodian’s address from the token’s issuer. This signature is used
        by mint contracts to determine the validity of a minting request. Any
        request to mint a token from an unauthorized custodian—one lacking the
        correct signature—will not be processed.{" "}
      </p>
      <p>
        When a developer issues a token representing ownership of an asset, they
        can either act as their own TAC or elect a third-party to act as a TAC.
        If a third-party is elected, the token issuer must sign the custodian’s
        address and the custodian must sign the mint contract for the token,
        confirming the mutually consensual relationship. The issuers signature
        grants the custodian the ability to mint tokens through the mint
        contract while the custodian’s signature ties the legal obligation of
        backing the token to the custodian. The developer of the token can
        designate as many or as few custodians as he or she sees fit, outlining
        the parameters of the custodial relationship in in the mint contract.{" "}
      </p>
      <p>
        Once a custodian’s address has received the necessary signature, it
        becomes a custodial address: the location of that custodian on the
        network.{" "}
      </p>
      <p>
        To better understand this concept, let’s use an example. If a bank
        wished to act as a gateway for US dollar tokens on the network, they
        would need to generate an address. Once approved by BASElayer Core—the
        issuer of the USD token—the bank would interface with the BASElayer
        network. Finally, BASElayer would sign the bank’s network address,
        converting it into a custodial address.{" "}
      </p>
      <p>
        Interfacing with the BASElayer platform as a custodian is a simple,
        streamline process. There are two to three steps. First, the custodian
        sets up an account within its own accounting system for BASElayer. At a
        bank, this would simply be a bank account in BASElayer’s name. It is
        here that the asset being tokenized will be held in custody. Second, the
        custodian integrates the Custodial API (CAPI) into its accounting
        system. While this may sound complicated, the API is simply a method for
        communicating deposits to the network and withdraws to the custodians
        accounting system. Third, if the custodian is applying to become a CCC
        it must set up a trusted node. Note this step does not apply if the
        custodian is a TPC.{" "}
      </p>
      <p>
        Technically, the use of the CAPI is not necessary. It is simply a set of
        functions that help a custodial address communicate with systems outside
        of BASElayer’s network. Its purpose is to automate the accounting
        process, which can be done manually if the custodian prefers it. In
        cases were a token is not constantly being redeemed, like a small vendor
        selling a single item, the use of the CAPI might not be necessary.{" "}
      </p>
      <h3 id="Please-Note">Please Note </h3>
      <p>
        {" "}
        BASElayer is a growing project and is working towards meshing bank and
        other financial accounts with BASElayer accounts. Through the CAPI we
        aim to create a seamless user experience where a user’s bank account (at
        a CCC) is also an address on the BASElayer network. This way, a user can
        harness the power of the BASElayer platform without any extra steps.{" "}
      </p>
      <h3 id="BASElayer-Node-Hosting-Service">
        BASElayer Node Hosting Service™{" "}
      </h3>
      <p>
        {" "}
        If a CCC does not have the resources to run a node or does not wish to
        host a node, it can run one through BASElayer Node Hosting Service™. In
        this case, the custodian’s node is hosed on BASElayer servers for which
        the custodian pays a monthly service fee. However, the custodian still
        retains ownership of the custodial address linked to the node, and
        therefore still receives the node holder reward. While the service fee
        is static, the node holder reward is dependent on network traffic. This
        means that, in a mature network, hosting a node through BASElayer is
        still profitable for the node owner.{" "}
      </p>
      <p></p>
    </div>
  );
};
const EntryAndExit = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Entry-and-Exit">Entry and Exit Process</h1>
      <span>
        How different types of custodians facilitate entries into and exits from
        the BASElayer platform
      </span>
      <p>
        The asset diversity of the BASElayer platform results in many different
        types of entry and exit processes. While CCCs may choose to conduct
        withdraws and deposits in one way, small vendors my follow an entirely
        different procedure. To better understand this concept, let’s use an
        example.{" "}
      </p>
      <p>
        Alice would like to fund her address on the network. She makes a deposit
        in US dollars at her local bank which is a CCC on the BASElayer
        platform. The bank enters the deposit into its computer system. Through
        the CAPI and the bank’s custodial address a mint request if sent to a
        USD Mint Smart Contract. The request contains Alice’s address and
        deposit information. The digital USD tokens are minted and forwarded to
        Alice’s BASElayer address. As long as the token exists, the underlying
        USD funds continue to be held by the CCC. Only if the token (or any
        successor token) is redeemed for USD cash, can the custodian relay the
        funds.{" "}
      </p>
      <p>
        While a bank or holding house might follows the steps outlined above,
        small vendors might handle it differently. Let’s say Bob is selling a
        guitar on an exchange built on BASElayer. When Bob tokenizes the guitar,
        he sets the exchange rate at 200 USD, lists himself as the custodian,
        and stipulates that the redeemer of the token must provide a shipping
        address (all within the mint contract). Alice purchase the guitar tokens
        using the USD tokens she acquired in the previous example. To ‘Check
        Out’ Alice sends the Guitar token along with the required information to
        Bob’s custodial address.{" "}
      </p>
      <p>
        Note that most of these processes can be automated. An exchange built on
        BASElayer could operate in much the same way eBay™ does without the user
        realizing what is going on in the background. The advantage of this type
        of exchange would be that any asset on the BASElayer platform could be
        used as payment.{" "}
      </p>
      <p>
        Lastly, for core currencies, if a user wishes to exit the network using
        a custodian that does not deal in the currency he or she is using to
        exit the network, an automated exchange takes place. It is possible for
        Bob, who now owns 200 USD tokens, to redeem his tokens at a bank that
        only deals in Euros. In this case, the network would automatically
        exchange the USD for EURO tokens at the current exchange rate. The bank
        could then facilitate in the withdraw of these Euros.
      </p>
    </div>
  );
};
const CCCFramework = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="CCC-Framework">CCC Framework</h1>
      <span>
        The requirements for Core Currency Custodians on the BASElayer network
      </span>
      <p>
        The requirements for a TAC are entirely up to the issuer of the token
        they are providing custodial services for. CCCs however must abide by a
        set of rules laid out by BASElayer Core. This CCC framework is used to
        insure regulatory standards are upheld at all times and that the
        custodians remain fully compatible with the network. For example, CCCs
        must specify which currencies they deal in so that they will not receive
        withdraw requests for currencies they do not hold in custody. The full
        list of a requirements can be found in the Legal Enforcement section of
        this paper
      </p>
    </div>
  );
};

export { Custodians, InterfacingWithBASElayer, EntryAndExit, CCCFramework };
