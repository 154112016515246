import "./style.css";
import React, { useState } from "react";
import { useLocation, NavLink } from "react-router-dom";

import Logo from "../../media/logo.png";
import NavComp from "../../comps/NavComp";

const App = () => {
  const [open, setOpen] = useState(false);
  const [docs, setDocs] = useState(true);
  const [follow, setFollow] = useState(false);

  const gernral = [
    { title: "Getting Started", link: "/Docs", anch: ["Getting-Started"] },
    {
      title: "Key Concepts",
      link: "/Docs/Key-Concepts",
      anch: ["Key-Concepts"],
      subs: [
        {
          title: "Blockchain",
          link: "/Docs/Key-Concepts/Blockchain",
          anch: ["Blockchain"],
        },
        {
          title: "Addresses",
          link: "/Docs/Key-Concepts/Addresses",
          anch: ["Addresses"],
        },
        {
          title: "UTOs",
          link: "/Docs/Key-Concepts/Unspent-Transaction-Outputs",
          anch: ["Unspent-Transaction-Outputs"],
        },
        {
          title: "Smart Contracts",
          link: "/Docs/Key-Concepts/Smart-Contracts",
          anch: ["Smart-Contracts"],
        },
        {
          title: "Tokenization",
          link: "/Docs/Key-Concepts/Tokenization",
          anch: ["Tokenization"],
        },
        {
          title: "Merkle Trees",
          link: "/Docs/Key-Concepts/Merkle-Trees ",
          anch: ["Merkle-Trees"],
        },
      ],
    },
  ];
  const onChain = [
    {
      title: "Introduction",
      link: "/Docs/Introduction",
      anch: ["Introduction"],
    },
    {
      title: "Assets",
      link: "/Docs/Assets",
      anch: ["assets"],
      subs: [
        {
          title: "Core Currencies",
          link: "/Docs/Assets/Core-Currencies",
          anch: ["Core-Currencies", "Exchange-Rates"],
        },
        {
          title: "Tokenized Assets",
          link: "/Docs/Assets/Tokenized-Assets",
          anch: ["Tokenized-Assets", "BL20-Standards"],
        },
      ],
    },
    {
      title: "Signatures",
      link: "/Docs/Signatures",
      anch: ["signatures"],
      subs: [
        {
          title: "Signatures Structure",
          link: "/Docs/Signatures/Signatures-Structure",
          anch: ["Signatures-Structure", "Addresses", "Contracts", "UTXOs"],
        },
        {
          title: "Authority Substructures",
          link: "/Docs/Signatures/Authority-Substructures",
          anch: ["Authority-Substructures"],
        },
      ],
    },
    {
      title: "Address Types",
      link: "/Docs/Address-Types",
      anch: ["Addresses"],
      subs: [
        {
          title: "Basic Address",
          link: "/Docs/Address-Types/Basic-Address",
          anch: ["Basic-Address"],
        },
        {
          title: "Custodial Address",
          link: "/Docs/Address-Types/Custodial-Address",
          anch: ["Custodial-Address", "Third-party-Asset-Custodians-(TPC)"],
        },
        {
          title: "Contract Address",
          link: "/Docs/Address-Types/Contract-Address",
          anch: ["Contract-Address"],
        },
      ],
    },
    {
      title: "Network",
      link: "/Docs/Network",
      anch: ["Network"],
      subs: [
        {
          title: "Network Fees",
          link: "/Docs/Network/Network-Fees",
          anch: [
            "Network-Fees",
            "Tokenized-Assets/Non-Core-Currencies",
            "Contract-Fees",
            "Adaptive-Fees",
          ],
        },
        {
          title: "Nodes",
          link: "/Docs/Network/Nodes",
          anch: ["Nodes", "Trusted-Nodes-(Nodes)", "Peer-Nodes-(Peers)"],
        },
        {
          title: "Consensus",
          link: "/Docs/Network/Consensus",
          anch: [
            "Consensus",
            "Avalanche",
            "Transaction-Process",
            "Block-Time/Transaction-Speed",
          ],
        },
        {
          title: "Block Structure",
          link: "/Docs/Network/Block-Structure",
          anch: ["Block-Structure", "Block-Header", "Block-Body"],
        },
      ],
    },
    {
      title: "Interoperability",
      link: "/Docs/Interoperability",
      anch: ["Interoperability"],
      subs: [
        {
          title: "Networkwide Merkle Root",
          link: "/Docs/Interoperability/Networkwide-Merkle-Root",
          anch: ["Networkwide-Merkle-Root"],
        },
        {
          title: "Federated Chains",
          link: "/Docs/Interoperability/Federated-Chains",
          anch: [
            "Federated-Chains",
            "Order Routing",
            "Nascent-Chain-Securitys",
          ],
        },
        {
          title: "Cross-Chain Addresses",
          link: "/Docs/Interoperability/Cross-Chain-Addresses",
          anch: ["Cross-Chain-Addresses"],
        },
        {
          title: "Horizontal Scalability",
          link: "/Docs/Interoperability/Horizontal-Scalability",
          anch: ["Horizontal-Scalability"],
        },
      ],
    },
    {
      title: "Virtual Machine",
      link: "/Docs/Virtual-Machine",
      anch: ["Virtual-Machine"],
      subs: [
        {
          title: "Contracts",
          link: "/Docs/Virtual-Machine/Smart-Contracts",
          anch: [
            "Smart-Contracts",
            "Interoperable-Contracts",
            "Pointers",
            "Turing-Complete",
            "Processing-Fee",
            "Language-Agnosticism",
            "Contract-Types",
          ],
        },
        {
          title: "Runtime Environment",
          link: "/Docs/Virtual-Machine/Runtime-Environment",
          anch: ["Runtime-Environment", "Efficient-Scheduling"],
        },
      ],
    },
    {
      title: "Conclusion",
      link: "/Docs/Conclusion",
      anch: ["Conclusion"],
    },
  ];
  const offCahin = [
    { title: "Overview", link: "/Docs/Overview", anch: ["Overview"] },
    {
      title: "Custodians",
      link: "/Docs/Key-Custodians",
      anch: ["Key-Custodians"],
      subs: [
        {
          title: "InterfacingWithBASElayer",
          link: "/Docs/Custodians/Interfacing-with-BASElayer",
          anch: [
            "Interfacing-with-BASElayer",
            "Please-Note",
            "BASElayer-Node-Hosting-Service",
          ],
        },
        {
          title: "Entry And Exit",
          link: "/Docs/Custodians/Entry-and-Exit",
          anch: ["Entry-and-Exit"],
        },
        {
          title: "CCC Framework",
          link: "/Docs/Custodians/CCC-Framework",
          anch: ["CCC-Framework"],
        },
      ],
    },
    {
      title: "Aggregated Pool",
      link: "/Docs/Aggregated-Pool",
      anch: ["Aggregated-Pool"],
    },
    {
      title: "Legal Enforcement",
      link: "/Docs/Legal-Enforcement",
      anch: ["Legal-Enforcement"],
      subs: [
        {
          title: "Legality Of Smart Contracts",
          link: "/Docs/Legal-Enforcement/Legality-of-Smart-Contracts",
          anch: ["Legality-of-Smart-Contracts"],
        },
        {
          title: "CCC Regulatory Compliance",
          link: "/Docs/Legal-Enforcement/CCC-Regulatory-Compliance",
          anch: ["CCC-Regulatory-Complianc"],
        },
      ],
    },
  ];
  const summary = [
    { title: "Summary", link: "/Docs/Summary", anch: ["Conclusion"] },
    { title: "References", link: "/Docs/References", anch: [""] },
  ];

  const location = useLocation();

  React.useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <header className="flex-just-cent">
      <div className="flex-align-cent flex-just-btw">
        <div className="flex-align-cent">
          <a href="/" style={{ padding: 0 }}>
            <img src={Logo} />
          </a>
        </div>

        <nav className="flex-align-cent desktop-only">
          <li>
            <NavLink exact to="/" activeClassName="active-nav-item">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/Docs" activeClassName="active-nav-item">
              Docs
            </NavLink>
          </li>

          <li>
            <div onClick={() => setFollow(true)} className="fo-link">
              Follow
            </div>
          </li>
        </nav>

        <a
          className="desktop-only"
          href="mailto:matt@wolfe.io"
          style={{ padding: 0 }}
        >
          <button>Contact</button>
        </a>
        <svg
          onClick={() => setOpen(true)}
          className="modile-button mobile-only"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
          />
        </svg>
      </div>
      {follow ? (
        <div className="follow-cont-blk" onClick={() => setFollow(false)}>
          <div className="follow-card">
            <h3>Follow us</h3>
            <div>
              <a
                target="blank"
                href="https://www.linkedin.com/company/35557318/admin/"
              >
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z"
                  />
                </svg>
              </a>
              <a target="blank" href="https://www.reddit.com/r/BASElayer/">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12,2A10,10 0 0,0 2,12C2,16.42 4.87,20.17 8.84,21.5C9.34,21.58 9.5,21.27 9.5,21C9.5,20.77 9.5,20.14 9.5,19.31C6.73,19.91 6.14,17.97 6.14,17.97C5.68,16.81 5.03,16.5 5.03,16.5C4.12,15.88 5.1,15.9 5.1,15.9C6.1,15.97 6.63,16.93 6.63,16.93C7.5,18.45 8.97,18 9.54,17.76C9.63,17.11 9.89,16.67 10.17,16.42C7.95,16.17 5.62,15.31 5.62,11.5C5.62,10.39 6,9.5 6.65,8.79C6.55,8.54 6.2,7.5 6.75,6.15C6.75,6.15 7.59,5.88 9.5,7.17C10.29,6.95 11.15,6.84 12,6.84C12.85,6.84 13.71,6.95 14.5,7.17C16.41,5.88 17.25,6.15 17.25,6.15C17.8,7.5 17.45,8.54 17.35,8.79C18,9.5 18.38,10.39 18.38,11.5C18.38,15.32 16.04,16.16 13.81,16.41C14.17,16.72 14.5,17.33 14.5,18.26C14.5,19.6 14.5,20.68 14.5,21C14.5,21.27 14.66,21.59 15.17,21.5C19.14,20.16 22,16.42 22,12A10,10 0 0,0 12,2Z"
                  />
                </svg>
              </a>
              <a target="blank" href="https://github.com/baselayer-io">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M14.5 15.41C14.58 15.5 14.58 15.69 14.5 15.8C13.77 16.5 12.41 16.56 12 16.56C11.61 16.56 10.25 16.5 9.54 15.8C9.44 15.69 9.44 15.5 9.54 15.41C9.65 15.31 9.82 15.31 9.92 15.41C10.38 15.87 11.33 16 12 16C12.69 16 13.66 15.87 14.1 15.41C14.21 15.31 14.38 15.31 14.5 15.41M10.75 13.04C10.75 12.47 10.28 12 9.71 12C9.14 12 8.67 12.47 8.67 13.04C8.67 13.61 9.14 14.09 9.71 14.08C10.28 14.08 10.75 13.61 10.75 13.04M14.29 12C13.72 12 13.25 12.5 13.25 13.05S13.72 14.09 14.29 14.09C14.86 14.09 15.33 13.61 15.33 13.05C15.33 12.5 14.86 12 14.29 12M22 12C22 17.5 17.5 22 12 22S2 17.5 2 12C2 6.5 6.5 2 12 2S22 6.5 22 12M18.67 12C18.67 11.19 18 10.54 17.22 10.54C16.82 10.54 16.46 10.7 16.2 10.95C15.2 10.23 13.83 9.77 12.3 9.71L12.97 6.58L15.14 7.05C15.16 7.6 15.62 8.04 16.18 8.04C16.75 8.04 17.22 7.57 17.22 7C17.22 6.43 16.75 5.96 16.18 5.96C15.77 5.96 15.41 6.2 15.25 6.55L12.82 6.03C12.75 6 12.68 6.03 12.63 6.07C12.57 6.11 12.54 6.17 12.53 6.24L11.79 9.72C10.24 9.77 8.84 10.23 7.82 10.96C7.56 10.71 7.2 10.56 6.81 10.56C6 10.56 5.35 11.21 5.35 12C5.35 12.61 5.71 13.11 6.21 13.34C6.19 13.5 6.18 13.62 6.18 13.78C6.18 16 8.79 17.85 12 17.85C15.23 17.85 17.85 16.03 17.85 13.78C17.85 13.64 17.84 13.5 17.81 13.34C18.31 13.11 18.67 12.6 18.67 12Z"
                  />
                </svg>
              </a>
              <a target="blank" href="https://medium.com/baselayer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  >
                  <path
                    fill="currentColor"
                    d="M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {open ? (
        <div className="sidenav-cont-blk" onClick={() => setOpen(false)}>
          <div
            className="sidenav-cont-nav"
            onClick={(e) => e.stopPropagation()}
          >
            <nav>
              <li>
                <NavLink
                  exact
                  to="/"
                  activeClassName="active-nav-item "
                  className="top-level"
                >
                  Home
                </NavLink>
              </li>
              <li className={`top-level-li ${docs ? "open" : ""}`}>
                <NavLink
                  to="/Docs"
                  activeClassName="active-nav-item "
                  className="top-level"
                >
                  Docs
                </NavLink>
                <button onClick={() => setDocs(!docs)}>
                  <svg className="docssvg" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"
                    />
                  </svg>
                </button>
              </li>
              {docs ? (
                <div className="docs-nav">
                  <NavComp cont={gernral} />
                  <div className="nav-section-div">On Chain</div>
                  <NavComp cont={onChain} />
                  <div className="nav-section-div">Off Chain</div>
                  <NavComp cont={offCahin} />
                  <div className="nav-section-div">Conclusion</div>
                  <NavComp cont={summary} />
                </div>
              ) : (
                ""
              )}
              <li>
                <div
                  onClick={() => {
                    setFollow(true);
                    setOpen(false);
                  }}
                  className="top-level"
                >
                  Follow
                </div>
              </li>
            </nav>
          </div>
        </div>
      ) : (
        ""
      )}
    </header>
  );
};

export default App;
