import React, { useState } from "react";
import "./style.css";

import Card from "../../comps/Card";
import Matt from "../../media/Matt.jpg";
import Will from "../../media/will.jpg";
import john from "../../media/john.jpg";

const App = (props) => {
  const [state, setState] = useState("JOHN JACOBS");

  const info = [
    {
      img: Will,
      name: "WILLIAM WOLFE",
      title: "Developer",
      text:
        "BBrief description of stuff about this person and what they bring to the table.",
    },
    {
      img: john,
      name: "JOHN JACOBS",
      title: "Co-Founder",
      text:
        "BBrief description of stuff about this person and what they bring to the table.",
    },
    {
      img: Matt,
      name: "MATT WOLFE",
      title: "Developer",
      text:
        "BBrief description of stuff about this person and what they bring to the table.",
    },
  ];

  return (
    <div className="about-wrap">
      <div>
        <h3>TEAM MEMBERS</h3>
        <h2>Meet The Leadership</h2>
        <p className="about-p">
          BASElayer’s leaders bring together experience in technology, financial
          services and regulatory compliance.
        </p>
      </div>
      <div>
        <div className="flex-align-cent flex-just-cent about-card-cont">
          {info.map((cont) => (
            <Card
              cont={cont}
              func={setState}
              active={state !== cont.name ? false : true}
            />
          ))}
        </div>
        <div
          className="flex-align-cent flex-just-cent about-card-dots-cont"
          style={{ margin: "56px 0" }}
        >
          {info.map((dot) => (
            <div
              className={`car-dot ${state !== dot.name ? "" : "active-dot"}`}
              onClick={(e) => setState(dot.name)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
