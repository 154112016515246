import React from "react";

const Summary = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Conclusion">CONCLUSION</h1>
      <span></span>
      <p>
        BASElayer’s robust custodial system is the foundation on which the
        platform resides. Its simple framework allows it to scale into whatever
        asset class or regulatory environment is requested. Its modular design
        facilitates consistent growth. And finally, its simplicity makes it an
        attractive prospect for legacy systems, hoping to be a part of the
        future financial landscape.
      </p>
    </div>
  );
};

export default Summary;
