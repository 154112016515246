import React from "react";

const Assets = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="assets">ASSETS</h1>
      <span></span>
      <p>
        The BASElayer platform allows any asset to be digitally stored, traded,
        and developed on. This “asset diversity” allows for developers to create
        value-based applications without relying on restrictive, costly third
        parties to handle the assets in question. For example, the regulatory
        hurdles, cost, and oversight required to set up a securities exchange
        make it impossible for an independent developer to do so. However, with
        a set of core currencies and legally binding contracts, BASElayer
        removes these barriers to entry. To better understand how this is
        accomplished let’s take a closer look at the assets on the BASElayer
        platform.
      </p>
    </div>
  );
};
const CoreCurrencies = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Core-Currencies">Core Currencies</h1>
      <span>The native currencies of the BASElayer platform</span>
      <p>
        Traditionally, Blockchain based platforms use a core token as the medium
        of exchange. In the case of Bitcoin this is BTC and in the case of
        Ethereum this is ETH. In both cases, the values of the tokens are
        determined solely by the market (rather than an underlying asset or
        government) and therefore are at risk of large swings in value. While
        the concept of a non-government backed currency has merit, the
        volatility makes adoption difficult for businesses, banks, and other
        non-speculative market makers. Consequently, BASElayer uses multiple
        fiat-backed tokens as its medium of exchange. These are referred to as
        BASElayer’s core currencies. . <p></p>
        BASElayer has five core currencies: US Dollars (USD), Euros (EUR),
        British Pounds (GBP), Japanese Yen (JPY) and Renminbi (CNY). The use of
        five currencies instead of one prevents any one token had an unfair
        technical advantage over the other. If this were the case, network
        traffic would comprise of the advantageous token while withdraws would
        comprise of all five tokens, making custody of network assets difficult
        to manage. Consequently, all five currencies, and their corresponding
        tokens, are hardcoded into the BASElayer framework (not enacted through
        smart contracts) giving them equal performance, security, and legal
        backing. . <p></p>
        Each of the five core currencies is denominated by its traditional
        ticker symbol. This is to avoid confusion as to what the token embodies.
        Each token has a 1 to 1 ratio with its underlying currency. One USD
        token is backed by one US dollar, one Euro token is backed by one Euro,
        and so on. This relationship is enforced and respected at all network
        access points. Consequently, the funds held in aggregate by the network
        custodians match the core currency tokens active on the network with a 1
        to 1 ratio. This ensures complete network solvency at any given time. .{" "}
      </p>
      <h2 id="Exchange-Rates">Exchange Rates</h2>
      <p>
        Exchange Rates – Exchange rates between core currencies are determined
        by Oracles. This allows for instant currency conversions, even at times
        when liquidity is an issue. Converting 1 USD to CNY, for example, would
        be executed at the most current market rate (for more information see
        Value Derivation). This does not mean that core currencies cannot be
        exchanged at different prices, only that the instant conversion feature
        built into the BASElayer (see modules) uses third-party exchange fees.
      </p>
    </div>
  );
};
const TokenizedAssets = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Tokenized-Assets">Tokenized Assets</h1>
      <span>How to introduce new assets onto the BASElayer platform</span>
      <p>
        BASElayer is built around the idea that all assets should be
        “programmable.” If you can move money around the internet, why not
        securities, real-estate, carbon credits, or any other asset? BASElayer
        enables developers to create tokens representing ownership of any
        underlying asset.{" "}
      </p>
      <p>
        Token issuance is made possible through Smart Contracts. More
        specifically, the token is created using a mint contract. The initial
        smart contract acts as a legally binding document specifying the
        attributes of the newly minted token. Throughout the token’s existence,
        it must adhere to its contract’s terms. Furthermore, third parties can
        cryptographically sign, or notarize, these contracts. This allows for
        government institutions, banks, holding houses, or even insurers to
        attach their services and credibility to the token. This notarization
        cannot be forged and is immutable. This allows users to build
        derivatives and instruments such as futures contracts, SEC compliant
        securities, FDIC ensured accounts, etc.{" "}
      </p>
      <p>
        To ensure a newly created token inherits all of the benefits associated
        with the BASElayer platform, it must comply with the BL20 Standard. The
        BL20 Standard is a set of contractually binding questions that must be
        answered before a token is introduced into the BASElayer ecosystem.
        Providing answers to all these questions ensures that a token inherits
        the legal backing of the BASElayer platform and takes full advantage of
        all the technical attributes the network has to offer.{" "}
      </p>
      <h2 id="BL20-Standards">BL20 Standard </h2>
      <p>
        The BL20 Standard describes the token attributes, asset attributes,
        events, and signatures that a token contract should implement to achieve
        complete compatibility with all BASElayer applications. A more detailed
        description of the standard can be found on the BASElayer GitHub.
      </p>
    </div>
  );
};

export { Assets, CoreCurrencies, TokenizedAssets };
