import React from "react";

const Conclusion = () => {
  return (
    <div className="text-elm-wrap">
      <h1>Runtime Environment</h1>
      <span>
        How nodes store and process data on the BASElayer virtual machine
      </span>
      <p>
        In making an online purchase today, a user must be conscious of what
        forms of payment a vendor accepts. Even if both the buyer and seller
        would like to conduct business in the same currency, they may not be
        able to complete the transaction. In fact, the notion that any one
        vehicle of value cannot be seamlessly exchanged for another—providing
        there exists a market—is unacceptable in today’s digital economy.
        BASElayer is designed to solve this problem. A world where a guest can
        check out of a hotel with shares of Microsoft or a shopper can buy a cup
        of coffee with real estate is possible; A truly global market. It is
        using these principles that the platform outlined in this paper is
        designed to become the base layer for all financial applications.
      </p>
      <h3>Resources </h3>
      <p>
        If you have any questions regarding the terms and ideas laid out in this
        paper, feel free to reach out to the BASElayer team at www.baselayer.io.
        BASElayer also borrows heavily from the technologies developed by
        Ripple©, Interledger™ and Komodo™. All three platforms provide an
        extensive amount of literature and resources to help users better
        understand these concepts.
      </p>
    </div>
  );
};

export default Conclusion;
