import React from "react";

const KeyConcepts = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Key-Concepts">Key Concepts</h1>
      <p>
        BASElayer’s mission is to make digital payments as simple as possible.
        Generating interest through obscure technical terminology or
        incomprehensible concepts goes against what the platform stands for.
        Rather, BASElayer’s appeal is evident when its technology can be clearly
        understood. Therefore, before delving any deeper, let’s first take a
        look at some of the blockchain technology’s key concepts. If you already
        have a firm understanding of this technology—UTXOs, Smart Contracts,
        Merkle Trees, etc.—feel free to skip ahead to the next section.
      </p>
    </div>
  );
};
const Blockchain = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Blockchain">Blockchain</h1>
      <span>
        The basic layout and subsequent characteristics of a blockchain network
      </span>
      <p>
        Modern technology allows people to exchange information such as voice
        and text from anywhere in the world, transferring value usually requires
        a trusted third-party. For example, banks are required in order to
        transfer sums of money. Trading securities requires an exchange. This
        reliance on third parties subjects transactions to points of failure,
        longer processing times, higher costs, and red tape. <p></p>
        The architecture of a blockchain disintermediates this complicated
        process of transferring value, eliminating the need for middlemen and
        third-party oversight. Ownership of money, goods, property, contracts,
        and even votes can be transferred over a blockchain between just two
        parties. <p></p>
        To start, let’s examine how a traditional payment network operates.
        We’ll use the example of Visa. <p></p>
        When you make an online purchase, your computer sends the transaction
        details to the seller’s bank. The seller’s bank then asks Visa if the
        transaction is valid. Visa uses your credit card information to locate
        your bank on their network. Once your bank confirms that you have the
        necessary funds, Visa alerts the seller’s bank, and the transaction is
        approved. <p></p>
        In this example, your bank, and the seller’s bank, are acting as
        nodes—participants in the network. These two nodes hold account balance
        information on what is known as a ledger—a list of who owns what.
        Because all transactions go through the payment processor, Visa is
        acting as the central node. Visa’s job is to direct information from
        your bank’s ledger to the ledger at the seller’s bank. <p></p>
        So, for a transaction to take place, your payment must go through a
        succession of three different authorities (your bank, Visa, and the
        seller’s bank). This is a linear process, so all three of these
        authorities must be 100% reliable at all times. If any one of them
        fails, the transaction will not go through. Moreover, each step in the
        process is accompanied by a fee. <p></p>
        In contrast, A blockchain distributes information to every node on the
        network. No central node is necessary. All network participants have
        copies of the same full ledger and can confirm transactions amongst
        themselves. This is analogous to you and the seller validating your own
        transaction because you both have full copies of the ledger. <p></p>
        Ultimately, a blockchain makes information permanent by recording it on
        a ledger that is copied over a large network of nodes. The information
        is committed to the common knowledge of all participants. <p></p>
        To better understand this concept, we’ll use an example. Let’s say
        everyone in the world knows Will has $5 and Bob has $5. In fact,
        everyone writes it down on their own piece of paper. Bob pays Will $2
        and lets everyone know. Now everyone in the world knows Will has $7 and
        Bob has $3 so they all update their pieces of paper to show the new
        balances. If Bob then tries to buy something for $5, the whole world
        would say “Hey, you can’t do that, everyone knows you only have $3.”{" "}
        <p></p>
        The pieces of paper that are used to keep track of Will’s and Bob’s
        balances represent the ledgers. When everyone updates their piece of
        paper, they are adding a new entry (block) to the ledger (chain). Hence
        the name blockchain. <p></p>
        When a transaction is initiated, the transaction request is sent out to
        all participants (nodes) on the network. Each node checks its own ledger
        to determine the validity of the transaction. If the majority of nodes
        on the network agree that the transaction is valid, the ledgers are all
        updated, and the transaction is permitted. This network wide agreement
        is known as reaching consensus. <p></p>
        In a blockchain, unlike other types of transactional systems, there is
        no central point of attack. Each node is held accountable by every other
        node. If a malicious node, like Bob, tries to make a dishonest
        transaction or falsify his ledger, the network rejects it because Bob’s
        ledger doesn’t agree with the other ledgers on the network. <p></p>
        Please note that, unlike the conventional payment processor’s linear
        network, reaching consensus is done in parallel. If any one node fails,
        the blockchain network can still function, and the transaction can still
        go through. <p></p>
        The information held in all the ledgers is the same, agreed by all the
        nodes to be accurate, and therefore irrefutable. We used the example of
        money, but any information can be made irrefutable using this technique.
        We could have just as easily said that everyone in the world knows Bob
        voted for candidate A and Will voted for Candidate B, and so on. Any
        scenario that requires trust can be reproduced on a blockchain. That
        being said, you probably wouldn’t want everyone knowing your financial
        information or who you voted for. That’s why blockchains use addresses
        to preserve anonymity. We will discuss this topic in the next section.
      </p>
    </div>
  );
};
const Addresses = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Addresses">Addresses</h1>
      <span>
        What a blockchain address is and how it is used within the network
      </span>
      <p>
        When you send an email, you use an email address to identify the
        recipient. This is exactly how addresses on a blockchain work. Simply
        put, an address is a location on the network. Anywhere information is
        sent must have an address. <p></p>
        Just like with an email, a blockchain address has a username (known as a
        public key) and a password (a private key). If Bob wants to send
        something to Will, he sends it to Will’s address, denoted by his public
        key. In order for Will to access his address, he will need to provide
        his private key. This is the same concept as the password Will is
        required to know when logging in to his email address. Without Will’s
        private key, there is no way of accessing his address on the blockchain.{" "}
        <p></p>
        Emails are a perfect analogy because they also demonstrate how anonymity
        is preserved. When you create an email address, you do not provide any
        personal information (assuming you do not include your name in the
        address). Consequently, there is no way for someone to tie your email
        back to you. <p></p>
        If Bob creates a new email address (abc@gmail.com) and sends an email to
        Will, Will has no way of knowing who is behind that address. Thus, Bob
        and Will can maintain a completely anonymous epistolary relationship.
        What’s more, Bob can create a new address any time he wants to
        communicate with someone new, further masking his identity.
      </p>
    </div>
  );
};
const UTOs = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Unspent-Transaction-Outputs">Unspent Transaction Outputs</h1>
      <span>what an unspent transaction output (UTXO) is and how it works</span>
      <p>
        When you purchase an item at the store, you pay in bills. If the item
        you were purchasing is $6, then you would probably pay with a $5 and $1
        bill. It would be impractical to pay for the item with pennies. In the
        same way, it would be impractical for a network to process a payment one
        penny at a time. In fact, BASElayer, as in the example above, processes
        payments in bills. <p></p>
        Unlike physical money, the bills used by a blockchain network can have
        unique sizes. For example, the US dollar has a 100, 50, 20, 10, 5 and $1
        bill. All payments need to be broken down into these bills. However,
        when a user makes a payment on a blockchain, they create a bill that
        matches the exact size of the payment. If you were to digitally purchase
        a $6 item, you would pay with a $6 bill. <p></p>
        These digital bills are called Unspent Transaction Outputs, or UTXOs for
        short. They are the reason a blockchain can quickly and efficiently move
        large sums of accounting information around a complicated network.{" "}
        <p></p>A user’s address holds a set of UTXOs much like how a wallet
        holds a set of bills. When a payment is made from an address, the
        network selects the UTXO that is closest to, but still larger than, the
        value of the payment. It breaks the UTXO into 2 separate UTXOs, one
        equal to the payment and one matching the remaining funds. The payment
        UTXO is then sent to the receiver while the remaining funds UTXO is
        returned to the sender. <p></p>
        In the event that a transaction has a value greater than any one UTXO in
        the sender’s address, the network will select multiple UTXOs whose sum
        is closest to, but greater than the payment. The UTXOs will be combined
        to form a UTXO for the payment and a UTXO for the remaining funds.{" "}
        <p></p>
        Ultimately, a UTXO is a digital currency bill. It aggregates funds into
        a single unit to lessen a transection’s burden on the network. The total
        balance held by an address is the sum of all the UTXOs in custody.{" "}
        <p></p>
      </p>
    </div>
  );
};
const SmartContracts = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Smart-Contracts">Smart Contracts</h1>
      <span>
        What smart contracts are and how they can be built into a blockchain
        network
      </span>
      <p>
        Blockchain architecture allows for the transfer of more than just
        wealth. The use of distributed ledgers allows for the digitization of
        any process that requires an intermediary. Contracts are a great
        example. <p></p>
        Traditionally, if you want to enter into a contract with someone, you
        need an unbiased third-party to act as intermediary. The intermediary
        determines whether each side has fulfilled its obligations and settles
        any disputes between the parties. <p></p>
        Let’s use a horse race as an example. Bob and Will are betting on
        whether or not a horse will finish a race first (the contract). They
        both place their bets with the teller (the intermediary). The race
        concludes, and Bob wins the bet. The teller then releases the funds to
        Bob. <p></p>
        This process can be automated on a blockchain using ‘Smart Contracts.’
        Smart contracts are addresses on the blockchain where an “if/ then”
        statement replaces the private key. This means that the only way to
        access the funds in the address is by completing the contract. To better
        understand this, let’s revisit the horse race example, only this time
        with the use of smart contracts. <p></p>
        Bob and Will are betting on a horse race. They create a smart contract
        that states “If the horse finished the race first then Bob wins the
        money.” Both Bob and Will send their bets to the smart contract’s
        address. Once the race has concluded, the contract, having been set up
        to check the race’s outcome on the racetrack’s website, determines that
        the horse finished first, and the funds are sent to Bob’s address.{" "}
        <p></p>
        As was the case with finances and votes, a smart contract is made
        irrefutable by distributing it amongst all the nodes on the network.
        Let’s again revisit the horse race example, only this time the
        ever-malevolent Bob will try to cheat the system. <p></p>
        Bob and Will enter into the same wager and send their bets to the smart
        contract. This contract is again copied to each node on the network.
        However, this time, the horse does not finish first and Will wins the
        bet. In an attempt to reverse his bet after the race, Bob alters the
        smart contract on his ledger to say that he wins if the horse does not
        finish first. Before the network can reach consensus and release the
        funds, each node checks its copy of the contract against the race’s
        outcome. All of the contracts except for Bob’s designate Will as the
        winner, and the funds are released to Will’s address. <p></p>
        With smart contracts, two parties can enter into an agreement knowing
        that they will both be held accountable by the other nodes on the
        network. The distribution of this contract across the network ensures
        that neither party can tamper with the contract once it is initiated.
        Furthermore, the contract can be observed by all network participants.
        Depending on how the contract was designed, multiple network
        participants might be able to enter into the contract. An example of
        this would be multiple network participants, not just Will and Bob,
        sending bets to the smart contract. In this case, the winnings would not
        be sent to just one address but the addresses of all those who won the
        bet. <p></p>
        As you can see, smart contracts are a fantastic way to build
        applications that traditionally rely on a third-party intermediary—a
        stipulation that is common in finance. While we used the example of
        betting, there are many other possibilities including escrow services,
        derivative creation, real-estate transaction, etc. <p></p>
      </p>
    </div>
  );
};
const Tokenization = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Tokenization">Tokenization</h1>
      <span>
        how real-world currencies can be converted into a digital, tradable
        asset
      </span>
      <p>
        Tokenization plays an integral part in BASElayer. To understand this
        idea, let’s use another example. Imagine you want to invest in an office
        building, but you only have a modest amount of money — say $5,000. How
        are you supposed to buy $5,000 dollars’ worth of a property that might
        be worth many millions? <p></p>
        Conversely, imagine that you have an office building, but you need cash
        for renovations. The building is valued at $5M but you just need
        $200,000. How do you sell $200,000 worth of this $5M building? <p></p>
        Enter tokenization. In its simplest form, tokenization is a method of
        certifying ownership in an asset with a digital token. Using the example
        above, tokenization can transform the ownership rights to the building
        into 10,000 tokens (the number is arbitrary). Thus, each token
        represents a 0.01% share of the underlying asset. Finally, we issue the
        token on a blockchain network so that the tokens can be freely traded.
        When someone buys one token, they actually purchase 0.01% ownership of
        the building. Buy 5,000 tokens and you own 50% of the building. Buy all
        10,000 tokens and you own 100% of the building. The blockchain’s network
        of immutable ledgers ensures that once you buy tokens, nobody can
        dispute your ownership. <p></p>
        While we used real estate as an example, nearly any asset –goods,
        services, and even fiat currencies – can be tokenized. <p></p>
        Tokenization allows us to exchange ownership of real-world items.
        Anything for which proof of ownership would otherwise be paper based can
        be tokenized – securities, real estate, commodities, bonds, etc. In
        doing so, we gain the advantages of security, speed, and low cost
        inherent in the blockchain, as well as the benefit of irrefutable proof
        of ownership.
      </p>
    </div>
  );
};
const MerkleTrees = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="MerkleTrees">Merkle Trees </h1>
      <span>What a Merkle tree is and how data is stored in this format</span>
      <p>
        The term Merkle Tree might sound daunting, but it is actually an
        incredibly simple concept. A Merkle Tree, as one might have guessed, is
        a tree structure for storing data. At the top level of the tree—we can
        think of it as the canopy—the most rudimentary pieces of information are
        stored. As we descend towards the trunk to the next level, pairs of data
        (two branches) are combined into a single piece of data referred to as a
        hash. <p></p>A hash is the result of a one-way function that takes two
        inputs and generates a single, fixed length output. It can more easily
        be thought of as a string of numbers and letters that contains
        information about its inputs. <p></p>
        Each subsequent layer of the tree provides a set of hashes for the
        preceding branches until the tree collapses down into a single hash,
        known as the root hash or Merkle root. This final hash is a string of
        digits that contains information about every branch in the tree. It can
        be used to quickly parse through all the data in the tree, making it an
        efficient storage method for complicated networks. <p></p>
        It is also important to note that a hash is a one-way cryptographic
        function. This means that changing the inputs of a hash function will
        change the hash but changing a hash will not have the reverse effect. In
        a system like a blockchains, where immutability of a transaction is
        paramount, hashes are very useful. <p></p>
        Blockchains use Merkle trees to generate a hash for a group of
        transactions. This group, referred to as a block, can then be
        efficiently linked to a chain of blocks via this block hash. As blocks
        continue to be added to the chains, the hashes continue to build off of
        one another—becoming more secure in the process. If a malicious actor
        tries to alter a transaction in a block that occurred three blocks
        prior, the hash of said block would change, immediately becoming
        incompatible with all three of the following blocks. <p></p>
        Blockchains are ever-growing sets of data. As a result, they require
        efficient and scalable data structures. Merkle Trees are the ideal
        solution.
      </p>
    </div>
  );
};

export {
  KeyConcepts,
  Blockchain,
  Addresses,
  UTOs,
  SmartContracts,
  Tokenization,
  MerkleTrees,
};
