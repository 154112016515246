import React, { useState } from "react";
import "./style.css";
// import { a } from "react-router-dom";

const App = (props) => {
  return (
    <div
      className={`content-wrap ${
        props.cont.direction < 0 ? "" : "rev-content-wrap"
      }`}
    >
      <div>
        <h3>{props.cont.subtitle}</h3>
        <h2>{props.cont.title}</h2>
        <p>{props.cont.text}</p>
        <a href={props.cont.link}>
          <button>{props.cont.button}</button>
        </a>
      </div>
      <img src={props.cont.img} />
    </div>
  );
};

export default App;
