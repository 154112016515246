import React, { useEffect } from "react";
import "./style.css";

import NavComp from "../../comps/NavComp";
import DocPage from "../../comps/DocPage";

const App = () => {
  const gernral = [
    { title: "Getting Started", link: "/Docs", anch: ["Getting-Started"] },
    {
      title: "Key Concepts",
      link: "/Docs/Key-Concepts",
      anch: ["Key-Concepts"],
      subs: [
        {
          title: "Blockchain",
          link: "/Docs/Key-Concepts/Blockchain",
          anch: ["Blockchain"],
        },
        {
          title: "Addresses",
          link: "/Docs/Key-Concepts/Addresses",
          anch: ["Addresses"],
        },
        {
          title: "UTOs",
          link: "/Docs/Key-Concepts/Unspent-Transaction-Outputs",
          anch: ["Unspent-Transaction-Outputs"],
        },
        {
          title: "Smart Contracts",
          link: "/Docs/Key-Concepts/Smart-Contracts",
          anch: ["Smart-Contracts"],
        },
        {
          title: "Tokenization",
          link: "/Docs/Key-Concepts/Tokenization",
          anch: ["Tokenization"],
        },
        {
          title: "Merkle Trees",
          link: "/Docs/Key-Concepts/Merkle-Trees ",
          anch: ["Merkle-Trees"],
        },
      ],
    },
  ];
  const onChain = [
    {
      title: "Introduction",
      link: "/Docs/Introduction",
      anch: ["Introduction"],
    },
    {
      title: "Assets",
      link: "/Docs/Assets",
      anch: ["assets"],
      subs: [
        {
          title: "Core Currencies",
          link: "/Docs/Assets/Core-Currencies",
          anch: ["Core-Currencies", "Exchange-Rates"],
        },
        {
          title: "Tokenized Assets",
          link: "/Docs/Assets/Tokenized-Assets",
          anch: ["Tokenized-Assets", "BL20-Standards"],
        },
      ],
    },
    {
      title: "Signatures",
      link: "/Docs/Signatures",
      anch: ["signatures"],
      subs: [
        {
          title: "Signatures Structure",
          link: "/Docs/Signatures/Signatures-Structure",
          anch: ["Signatures-Structure", "Addresses", "Contracts", "UTXOs"],
        },
        {
          title: "Authority Substructures",
          link: "/Docs/Signatures/Authority-Substructures",
          anch: ["Authority-Substructures"],
        },
      ],
    },
    {
      title: "Address Types",
      link: "/Docs/Address-Types",
      anch: ["Addresses"],
      subs: [
        {
          title: "Basic Address",
          link: "/Docs/Address-Types/Basic-Address",
          anch: ["Basic-Address"],
        },
        {
          title: "Custodial Address",
          link: "/Docs/Address-Types/Custodial-Address",
          anch: ["Custodial-Address", "Third-party-Asset-Custodians-(TPC)"],
        },
        {
          title: "Contract Address",
          link: "/Docs/Address-Types/Contract-Address",
          anch: ["Contract-Address"],
        },
      ],
    },
    {
      title: "Network",
      link: "/Docs/Network",
      anch: ["Network"],
      subs: [
        {
          title: "Network Fees",
          link: "/Docs/Network/Network-Fees",
          anch: [
            "Network-Fees",
            "Tokenized-Assets/Non-Core-Currencies",
            "Contract-Fees",
            "Adaptive-Fees",
          ],
        },
        {
          title: "Nodes",
          link: "/Docs/Network/Nodes",
          anch: ["Nodes", "Trusted-Nodes-(Nodes)", "Peer-Nodes-(Peers)"],
        },
        {
          title: "Consensus",
          link: "/Docs/Network/Consensus",
          anch: [
            "Consensus",
            "Avalanche",
            "Transaction-Process",
            "Block-Time/Transaction-Speed",
          ],
        },
        {
          title: "Block Structure",
          link: "/Docs/Network/Block-Structure",
          anch: ["Block-Structure", "Block-Header", "Block-Body"],
        },
      ],
    },
    {
      title: "Interoperability",
      link: "/Docs/Interoperability",
      anch: ["Interoperability"],
      subs: [
        {
          title: "Networkwide Merkle Root",
          link: "/Docs/Interoperability/Networkwide-Merkle-Root",
          anch: ["Networkwide-Merkle-Root"],
        },
        {
          title: "Federated Chains",
          link: "/Docs/Interoperability/Federated-Chains",
          anch: [
            "Federated-Chains",
            "Order Routing",
            "Nascent-Chain-Securitys",
          ],
        },
        {
          title: "Cross-Chain Addresses",
          link: "/Docs/Interoperability/Cross-Chain-Addresses",
          anch: ["Cross-Chain-Addresses"],
        },
        {
          title: "Horizontal Scalability",
          link: "/Docs/Interoperability/Horizontal-Scalability",
          anch: ["Horizontal-Scalability"],
        },
      ],
    },
    {
      title: "Virtual Machine",
      link: "/Docs/Virtual-Machine",
      anch: ["Virtual-Machine"],
      subs: [
        {
          title: "Contracts",
          link: "/Docs/Virtual-Machine/Smart-Contracts",
          anch: [
            "Smart-Contracts",
            "Interoperable-Contracts",
            "Pointers",
            "Turing-Complete",
            "Processing-Fee",
            "Language-Agnosticism",
            "Contract-Types",
          ],
        },
        {
          title: "Runtime Environment",
          link: "/Docs/Virtual-Machine/Runtime-Environment",
          anch: ["Runtime-Environment", "Efficient-Scheduling"],
        },
      ],
    },
    {
      title: "Conclusion",
      link: "/Docs/Conclusion",
      anch: ["Conclusion"],
    },
  ];
  const offCahin = [
    { title: "Overview", link: "/Docs/Overview", anch: ["Overview"] },
    {
      title: "Custodians",
      link: "/Docs/Key-Custodians",
      anch: ["Key-Custodians"],
      subs: [
        {
          title: "InterfacingWithBASElayer",
          link: "/Docs/Custodians/Interfacing-with-BASElayer",
          anch: [
            "Interfacing-with-BASElayer",
            "Please-Note",
            "BASElayer-Node-Hosting-Service",
          ],
        },
        {
          title: "Entry And Exit",
          link: "/Docs/Custodians/Entry-and-Exit",
          anch: ["Entry-and-Exit"],
        },
        {
          title: "CCC Framework",
          link: "/Docs/Custodians/CCC-Framework",
          anch: ["CCC-Framework"],
        },
      ],
    },
    {
      title: "Aggregated Pool",
      link: "/Docs/Aggregated-Pool",
      anch: ["Aggregated-Pool"],
    },
    {
      title: "Legal Enforcement",
      link: "/Docs/Legal-Enforcement",
      anch: ["Legal-Enforcement"],
      subs: [
        {
          title: "Legality Of Smart Contracts",
          link: "/Docs/Legal-Enforcement/Legality-of-Smart-Contracts",
          anch: ["Legality-of-Smart-Contracts"],
        },
        {
          title: "CCC Regulatory Compliance",
          link: "/Docs/Legal-Enforcement/CCC-Regulatory-Compliance",
          anch: ["CCC-Regulatory-Complianc"],
        },
      ],
    },
  ];
  const summary = [
    { title: "Summary", link: "/Docs/Summary", anch: ["Conclusion"] },
    { title: "References", link: "/Docs/References", anch: [""] },
  ];

  const overview = [];

  let all = [...gernral, ...onChain, ...offCahin, ...summary];
  all.forEach((item, i) => {
    if (!item.subs) return overview.push(item);
    let repack = { title: item.title, link: item.link, anch: item.anch };
    overview.push(repack);
    item.subs.forEach((subItem, ii) => {
      return overview.push(subItem);
    });
  });

  let link = [];
  overview.forEach((item, i) => {
    // link.push(item.link)
    let str = `<url>
  <loc>https://baselayer.io/${item.link}</loc>
  <lastmod>2021-01-18T15:36:51+00:00</lastmod>
</url>
  `;

    console.log(str);
  });

  // console.log(ref1.current ? ref1.current.children[0].div : "none");

  return (
    <section>
      <div className="docs-container content-wrapper">
        <nav className="docs-nav">
          <NavComp cont={gernral} />
          <div className="nav-section-div">On Chain</div>
          <NavComp cont={onChain} />
          <div className="nav-section-div">Off Chain</div>
          <NavComp cont={offCahin} />
          <div className="nav-section-div">Conclusion</div>
          <NavComp cont={summary} />
        </nav>
        <DocPage pages={overview} />
      </div>
    </section>
  );
};

export default App;
