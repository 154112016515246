import React from "react";

const VirtualMachine = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Virtual-Machine">VIRTUAL MACHINE </h1>
      <span></span>
      <p>
        BASElayer provides network participants access to a virtual machine in
        much the same way projects like Ethereum and EOS do. However, unlike
        these platforms, BASElayer does not have any issue scaling to meet heavy
        network loads. This is partially due to the cross-chain interoperability
        of the network but is made possible through a process scheduling and
        routing system. The final product, the BASElayer Virtual Machine (BVM
        for short), is a scalable runtime environment for asset agnostic, Turing
        complete smart contracts.
      </p>
    </div>
  );
};
const Contracts = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Smart-Contracts">Smart Contracts</h1>
      <span>
        The capabilities and architecture of contracts run on the BASElayer
        platform
      </span>
      <p>
        Smart contracts are the bread and butter of any virtual machine. They
        are the mechanism through which assets can be programmed. The abilities
        of these contacts subsequently determine how much a developer is able to
        actually do when programming financial applications. BASElayer
        consequently goes to great length to ensure that smart contracts on the
        platform offer developers complete programmability, in a scalable format
        across all assets.
      </p>
      <h2 id="Interoperable-Contracts">Interoperable Contracts </h2>
      <p>
        {" "}
        BASElayer is a UTXO based platform. While most UTXO platforms, like
        Bitcoin, are not capable of handling smart contracts, BASElayer utilizes
        the Crypto-Conditions Contacts methodology to build UTXO based smart
        contracts. While this may seem like a roundabout way of implementing
        smart contracts, it is the reason that contracts on the BASElayer
        platform are interoperable. Due to the UTXO architecture of all asset
        chains on the platform, any smart contract can interact with any asset.{" "}
      </p>
      <p>
        To better understand this concept, you can think of a smart contract as
        a lock box. Any currency in paper bill form can be deposited and held in
        the box. Similarly, a smart contract is capable of holding any UTXO.{" "}
      </p>
      <p>
        So how do UTXO contracts work? Traditionally, there are several
        different ways to execute a payment on a UTXO based blockchain.
        Primarily, there are Pay-To-Pubkey (p2pk) payments, Pay-To-Pubkey-Hash
        (p2pkh) payments, and Pay-to-Script-Hash (p2sh) payments.{" "}
      </p>
      <p>
        BASElayer uses unique, additional payment script that designates a UTXO
        as belonging to a specific smart contract. To do this, the UTXO in gets
        signed by a contract. Every BASElayer contract has its own unique
        signature so there is never any confusion about which contract a
        specific UTXO is entering.{" "}
      </p>
      <p>
        Because the UTXO is what is being signed (as opposed to an address
        taking ownership), and all blockchains on the network are UTXO based,
        contracts are able to take ownership (via signature) of any asset on the
        network.
      </p>
      <ul>
        <li id="Pointers">
          <b>Pointers</b> – Signatures on the BASElayer platform contain a
          pointer as to the location of the signature’s conditions. In the case
          of smart contracts this is particularly important because it points to
          the location of the contract’s bytecode. Due to a signature’s
          architecture, each blockchain is limited to housing 256 contracts.
          Therefore, it is not uncommon that a UTXO be controlled by a contract
          on another chain. With the pointer incorporated in the signature, if a
          node sees that a UTXO is owned by a contract, it can quickly obtain
          the parameters of the contract.{" "}
        </li>
      </ul>

      <h2 id="Turing-Complete">Turing Complete </h2>
      <p>
        To ensure that developers are capable of developing any application on
        the BASElayer platform, all Smart contracts are Turing complete. To
        better understand this concept, let’s take a look at what Turing
        complete means and why it is significant.{" "}
      </p>
      <p>
        A Turing complete machine is a machine that, when granted a few basic
        assumptions, can theoretically compute any computable sequence. That is
        to say that a Turing complete computer can compute any computation. It
        is, as Alan Turing put it, a “universal machine.”{" "}
      </p>
      <p>
        Thus, programing languages that can be used to write any conceivable
        computation are deemed Turing complete. The C base smart contracts of
        the BASElayer platform fit this description.{" "}
      </p>
      <p>
        Why is this significant? Why don’t all blockchains use Turing complete
        contracts? To answer these questions, we need to understand the concept
        of loops.{" "}
      </p>
      <p>
        In programing, a loop is a way to repeat a task n times, where n is an
        arbitrary number. If I wanted to a program to stop once it repeats ten
        times, it would be much easier to create a loop that stops when it
        reaches ten iterations then copying and pasting the same program ten
        times.{" "}
      </p>
      <p>
        But what happens if the number of iterations in a loop is not set? In
        this case, the loop will continue to run without any hope of stopping.
        This infinite loop is a serious problem, especially for platforms like
        BASElayer. If a loop is allowed to run without stopping, whether by
        error or malicious intent, it is an unwanted drain on the network’s
        resources.{" "}
      </p>
      <p>
        To combat this, projects like Bitcoin do not allow for loops to be
        written on their platform. However, disallowing loops in a programing
        language means that the language is not Turing complete and developers
        are limited in the scope of their creations.{" "}
      </p>
      <p>
        To solve this problem Ethereum introduced the concept of gas. In much
        the same way the gas determines how long your car can drive, Ethereum
        gas determines how long a process can continue to run. Once the gas runs
        out, then the program stops. This way, developers can develop anything
        using Turing complete smart contracts while the network does not need to
        worry about infinite loops.{" "}
      </p>
      <p>
        BASElayer employs a method similar to the latter example. Contracts are
        endowed with a mandatory processing fee valued at $0.01 by default (see
        Runtime Environment for more details). This fee is made payable to the
        nodes that process the contract. Developers can monetize their contracts
        by adding an additional fee of their choosing, however this is not
        mandatory.{" "}
      </p>
      <p>
        The aggregate fee—the summation of the processing and developer fees—is
        deducted from the funds being processed and is charged every time the
        contract it run. In contrast to Ethereum’s approach, this greatly
        simplifies the use of smart contracts, as a user is not required to
        estimate the amount of gas, he or she is willing to purchase.
        Additionally, it incentivizes users to use the most efficient and
        therefore cost-effective contracts available on the network.
      </p>

      <ul>
        <li id="Processing-Fee">
          <b>Processing Fee</b> – Please note that the processing fee is subject
          to reduction. BASElayer is a nascent project and if the pricing model
          for contracts can be lowered while preserving node profitability then
          it will. This is entirely dependent how the network is eventually used
          and the cost of running a node. Ideally, the fee would be set low
          enough that it does not hinder contract usage but high enough that it
          adds to the incentive to host a node.
        </li>
      </ul>

      <h2 id="Language-Agnosticism">Language Agnosticism </h2>
      <p>
        Platforms like BASElayer are made exponentially more powerful the more
        developers they can attract. Consequently, UTXO based smart contracts,
        compliant with Crypto-Conditions standards, are hugely advantageous due
        to their language agnosticism. This means that developers can chose the
        language they are most comfortable with when developing their
        applications.{" "}
      </p>
      <p>
        BASElayer’s smart contracts are written in C and C++. The C and C++
        programming languages are widely understood, time-tested, and, perhaps
        most importantly, Turing complete. Furthermore, contracts can also be
        written in any compiled programming language that can create a linkable
        library capable of calling and being called by C/C++ functions. In this
        way, smart contracts on the BASElayer platform are language agnostic.
      </p>
      <h2 id="Contract-Types">Contract Types </h2>
      <p>
        While contracts can be programmed to perform just about any computation,
        there are several types of contracts that BASElayer relies on more than
        others. It is therefore advantages to explain how these contracts work
        and how that are used.
      </p>
      <ul>
        <li>
          <p>
            <b>Oracles</b> – Oracles are integral to the BASElayer platform.
            They are the gateway through which third parity data enters the
            network. Their purpose to retrieve, verify, and publish this data so
            that all other network participants may use it.{" "}
          </p>
          <p>
            The act of converting a core currency into another core currency
            relies on the use of such oracles. In this case, oracles built and
            signed by BASElayer core collect exchange rate data from third-party
            exchanges (i.e. easyMarkets®, TXTM™, HYCM™, etc.) to calculate the
            number of tokens that need to be created on the destination chain.{" "}
          </p>
          <p>
            Any developer can create an oracle to meet the needs of a specific
            application. Furthermore, oracles developed for one application can
            be queried by other applications on the network. For example, if an
            oracle is created to obtain the most recent price of gold, all
            applications that require this information can use the output of
            this oracle.{" "}
          </p>
          <p>
            All that is needed to create an oracle is a specified web API. The
            oracle will then retrieve the information and post it to the chain.
            The validity of such information is entirely dependent on the API
            and it is up to the developers and notaries on the network to decide
            whether or not to use it.{" "}
          </p>
        </li>

        <li>
          <b>Modules</b> – To help developers build rich applications on the
          platform, BASElayer has taken the liberty of creating a small set of
          contracts that perform common tasks. These contracts are referred to
          as modules and can be called by any other contracts or users on the
          network. These modules are built to meet all the regulatory
          requirements associated with their subject matter. The modules are
          categorized by their intent.
          <ul>
            <li>
              Asset and Derivative Creation
              <ul>
                <li>
                  Mint Contract – This contract allows developers mint specified
                  (non-core) tokens if specified signatures are provided.
                </li>
                <li>
                  Withdraw Contract – This contract forwards withdraw
                  information to the correct custodian and burns the received
                  tokens.
                </li>
                <li>
                  Bundling– This contract acts as both a mint contract and a
                  virtual custodian, issuing a token representing ownership of
                  the underlying.
                </li>
                <li>
                  Fixed Income– This contract allows for the tokenization of
                  debt.
                </li>
                <li>
                  Obligatory Instruments– This contract allows for the creation
                  of tokens signaling an obligation or right to a an underlying
                  (options, futures, etc.).
                </li>
              </ul>
            </li>
            <li>
              Adding Features to Addresses
              <ul>
                <li>
                  Know Your Customer (Deposited Authorization) – This contract
                  allows developers to add KYC requirements to an address.
                </li>
                <li>
                  Multi-Signature Addresses– This contract allows developers to
                  add Multi-signature requirements to an address.
                </li>
              </ul>
            </li>
            <li>
              Adding Payment Types
              <ul>
                <li>
                  Checks – This contract allows developers to add checks as a
                  possible form of payment. It includes an expiration and
                  cancelation ability.
                </li>
                <li>
                  Escrow – This contract allows developers to add escrow
                  parameters to a payment, allowing for funds to be locked until
                  conditions are met.
                </li>
              </ul>
            </li>
            <li>
              Adding Signature Validation
              <ul>
                <li>
                  Signature Requirements – This contract allows developers to
                  add conditional clauses related to signatures.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
const RuntimeEnvironment = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Runtime-Environment">Runtime Environment</h1>
      <span>
        How nodes store and process data on the BASElayer virtual machine
      </span>
      <p>
        In addition to all the other services offered on the platform, BASElayer
        provides an incredibly efficient, scalable virtual machine. While many
        other projects have implemented their own virtual machine, to various
        degrees of success, BASElayer’s trusted node system gives it a
        tremendous advantage.{" "}
      </p>
      <p>
        In the case of Ethereum, the entire network is required to process a
        command and agree upon its outcome. This is analogous to a tollbooth on
        a single lane road. No matter how many cars are on the road, they can
        only pass through the tollbooth one at a time.{" "}
      </p>
      <p>
        This is incredibly inefficient, as an increase in the number of commands
        increase the processing time by a 1 to 1 ratio.{" "}
      </p>
      <p>
        This is why BASElayer uses a parallel processing approach. To continue
        the tollbooth analogy, BASElayer has a multilane road, each lane
        sporting its own tollbooth. Using this approach, the network can handle
        significantly more traffic.{" "}
      </p>
      <p>
        This breakthrough is made possible by BASElayer’s trusted node network.{" "}
      </p>
      <p>
        Because node holders undergo an approval process, are primarily
        institutional, and are incentivized to act honestly, contracts can be
        processed by a small, random cluster of nodes instead of the entire
        network. Each cluster effectively acts as its own core in the multicore
        processor that is the BVM.{" "}
      </p>
      <p>
        On networks like Ethereum, this would not be possible because malicious
        actors could host a mathematically significant number of nodes so as to
        receive and process their own contracts. BASElayer’s trusted node
        network avoids this while preserving decentralization on an networkwide
        level.{" "}
      </p>
      <p>
        Furthermore, routing processes through clusters of nodes allows the
        network to scale its processing power as the number of nodes grows. This
        is yet another feature unique to the trusted node network and not
        possible on other platforms.{" "}
      </p>
      <p>
        After a cluster comes to an agreement on the outcome of a contract, the
        output spreads throughout the trusted node network during the next
        consensus round.{" "}
      </p>
      <h2>Certainty </h2>
      <p>
        {" "}
        When processing a contract, the network checks the contract’s processing
        fee to determine the number of nodes required to process it. If a user
        leaves the processing fee in its default state, then the minimum number
        of nodes will process the contract. A user can subsequently increase
        this fee, resulting in a higher number of nodes being paid for their
        processing. This lets users pay for certainty regarding a contract’s
        outcome.{" "}
      </p>
      <p>
        Please note that certainty is built into the BASElayer platform, but it
        is conceivable that there are certain applications that might require a
        greater level of certainty than is offered in the default state. Because
        this is not necessary in all instances it does not make sense for
        contracts that do not require it to incur fees for it.{" "}
      </p>
      <h2 id="Efficient-Scheduling">Efficient Scheduling </h2>
      <p>
        {" "}
        BASElayer uses an efficient scheduling protocol to determine how the
        network as a whole should process contracts. In doing so, specific
        processes are able to be run in parallel within a node cluster. This
        works in much the same way Hyper-Threading does on a CPU.
      </p>
    </div>
  );
};

export { VirtualMachine, Contracts, RuntimeEnvironment };
