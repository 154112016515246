import "./App.css";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from "./comps/Header";
import Footer from "./comps/Footer";
import Home from "./pages/Home";
import Docs from "./pages/Docs";

const App = () => {
  return (
    <div className="App">
      <Header />
      <div style={{ overflow: "hidden" }}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/Docs" component={Docs} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

export default App;
