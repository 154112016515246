import "./style.css";
import React, { useState } from "react";

const App = (props) => {
  return (
    <div
      className={`Card flex-col flex-align-cent ${
        props.active ? "active-card" : ""
      }`}
      onClick={(e) => props.func(props.cont.name)}
    >
      <img
        src={props.cont.img ? props.cont.img : ""}
        alt={props.cont.name ? props.name : ""}
      />
      <h4>{props.cont.name}</h4>
      <h5>{props.cont.title}</h5>
      <p>{props.cont.text}</p>
    </div>
  );
};

export default App;

// <Landing />
// <img src={Tile} />
