import React from "react";

const References = () => {
  return (
    <div className="text-elm-wrap">
      <ul>
        <li>
          {" "}
          (2019). Eba.europa.eu. Retrieved 14 January 2019, from
          https://eba.europa.eu/documents/10180/657547/EBA-Op-2014-08+Opinion+on+Virtual+Currencies.pdf
        </li>
        <li>
          {" "}
          (2019). Fatf-gafi.org. Retrieved 14 January 2019, from
          http://www.fatf-gafi.org/media/fatf/documents/reports/Guidance-RBA-money-value-transfer-services.pdf
        </li>
        <li>
          {" "}
          (2019). Ripple.com. Retrieved 14 January 2019, from
          https://ripple.com/files/ripple_consensus_whitepaper.pdf
        </li>
        <li>
          {" "}
          Alan Turing. (2019). En.wikipedia.org. Retrieved 14 January 2019, from
          https://en.wikipedia.org/wiki/Alan_Turing
        </li>
        <li>
          {" "}
          An Introduction to Smart Contracts and Their Potential and Inherent
          Limitations. (2019). Corpgov.law.harvard.edu. Retrieved 14 January
          2019, from
          https://corpgov.law.harvard.edu/2018/05/26/an-introduction-to-smart-contracts-and-their-potential-and-inherent-limitations/
        </li>
        <li>
          {" "}
          Documents - Financial Action Task Force (FATF). (2019). Fatf-gafi.org.
          Retrieved 14 January 2019, from
          http://www.fatf-gafi.org/publications/fatfrecommendations/documents/fatf-recommendations.html
        </li>
        <li>
          {" "}
          draft-thomas-crypto-conditions-04 - Crypto-Conditions. (2019).
          Tools.ietf.org. Retrieved 14 January 2019, from
          https://tools.ietf.org/html/draft-thomas-crypto-conditions-04
        </li>
        <li>
          {" "}
          easyMarkets | Reinventing Trading Since 2001. (2019).
          Lnd.easymarkets.com. Retrieved 14 January 2019, from
          https://lnd.easymarkets.com/int/en/reinventing-trading-2001/?pid=Affiliate&utm_ma_token=8oIuw8RrT2NM4f-aiBZ4j2Nd7ZgqdRLk&utm_ef_channel=Mobile_app&utm_content=EM_Home&utm_source=Internal&utm_medium=Affiliate&utm_ef_promotion=No-Promotion&utm_campaign=Brand&utm_placement=992915&c=992915
        </li>
        <li>
          {" "}
          ERC20 Token Standard - The Ethereum Wiki. (2019). Theethereum.wiki.
          Retrieved 14 January 2019, from
          https://theethereum.wiki/w/index.php/ERC20_Token_Standard
        </li>
        <li>
          {" "}
          ethereum/wiki. (2019). GitHub. Retrieved 14 January 2019, from
          https://github.com/ethereum/wiki/wiki/White-Paper
        </li>
        <li>
          {" "}
          Home - Komodo. (2019). Komodo. Retrieved 14 January 2019, from
          https://www.komodoplatform.com/
        </li>
        <li>
          {" "}
          Home - XRP Ledger Dev Portal. (2019). Developers.ripple.com. Retrieved
          14 January 2019, from https://developers.ripple.com/index.html
        </li>
        <li>
          {" "}
          Intel® Hyper-Threading Technology. (2019). Intel. Retrieved 14 January
          2019, from
          https://www.intel.com/content/www/us/en/architecture-and-technology/hyper-threading/hyper-threading-technology.html
        </li>
        <li>
          {" "}
          KomodoPlatform/Mastering_CryptoConditions. (2019). GitHub. Retrieved
          14 January 2019, from
          https://github.com/KomodoPlatform/Mastering_CryptoConditions/blob/master/all_chapters.md
        </li>
        <li>
          {" "}
          Online Manual - BSA InfoBase - FFIEC. (2019). Ffiec.gov. Retrieved 14
          January 2019, from
          https://www.ffiec.gov/bsa_aml_infobase/pages_manual/OLM_015.htm
        </li>
        <li>
          {" "}
          Open Trading Account at ForexTime (FXTM) | ForexTime (FXTM). (2019).
          ForexTime (FXTM). Retrieved 14 January 2019, from
          https://www.forextime.com/register/open-account?utm_source=forex-ratings.com&utm_medium=display&utm_content=100x33&utm_campaign=open-account&utm_blocka=mediabu
        </li>
        <li>
          {" "}
          Start Trading. (2019). Hycm.com. Retrieved 14 January 2019, from
          https://www.hycm.com/en/hylp/global/start-trading?campaignid=701D0000001hqUz&utm_source=Forex-ratings.com&utm_medium=listing&utm_campaign=Forex-ratings.com_listing&utm_term=EN&utm_content=Demo
        </li>
        <li>
          {" "}
          Welcome | Interledger. (2019). Interledger.org. Retrieved 14 January
          2019, from https://interledger.org/
        </li>
      </ul>
    </div>
  );
};

export default References;
