import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import "./style.css";

import GettingStarted from "../../cont/GettingStarted";
import {
  KeyConcepts,
  Blockchain,
  Addresses,
  UTOs,
  SmartContracts,
  Tokenization,
  MerkleTrees,
} from "../../cont/KeyConcepts";
import Introduction from "../../cont/Introduction";
import { Assets, CoreCurrencies, TokenizedAssets } from "../../cont/Assets";
import {
  Signatures,
  SignaturesStructure,
  AuthoritySubstructures,
} from "../../cont/Signatures";
import {
  AddressTypes,
  BasicAddress,
  CustodialAddress,
  ContractAddress,
} from "../../cont/AddressTypes";
import {
  Network,
  NetworkFees,
  Nodes,
  Consensus,
  BlockStructure,
} from "../../cont/Network";
import {
  Interoperability,
  NetworkwideMerkleRoot,
  FederatedChains,
  CrossChainAddresses,
  HorizontalScalability,
} from "../../cont/Interoperability";
import {
  VirtualMachine,
  Contracts,
  RuntimeEnvironment,
} from "../../cont/VirtualMachine";
import Conclusion from "../../cont/Conclusion";
import Overview from "../../cont/Overview";
import {
  Custodians,
  InterfacingWithBASElayer,
  EntryAndExit,
  CCCFramework,
} from "../../cont/Custodians";
import AggregatedPool from "../../cont/AggregatedPool";
import {
  LegalEnforcement,
  LegalityOfSmartContracts,
  CCCRegulatoryCompliance,
} from "../../cont/LegalEnforcement";
import Summary from "../../cont/Summary";
import References from "../../cont/References";
import { NavHashLink } from "react-router-hash-link";

const App = (props) => {
  const [pageNumb, setPageNumb] = useState(0);
  const { pages } = props;
  const comps = [
    GettingStarted,
    KeyConcepts,
    Blockchain,
    Addresses,
    UTOs,
    SmartContracts,
    Tokenization,
    MerkleTrees,
    Introduction,
    Assets,
    CoreCurrencies,
    TokenizedAssets,
    Signatures,
    SignaturesStructure,
    AuthoritySubstructures,
    AddressTypes,
    BasicAddress,
    CustodialAddress,
    ContractAddress,
    Network,
    NetworkFees,
    Nodes,
    Consensus,
    BlockStructure,
    Interoperability,
    NetworkwideMerkleRoot,
    FederatedChains,
    CrossChainAddresses,
    HorizontalScalability,
    VirtualMachine,
    Contracts,
    RuntimeEnvironment,
    Conclusion,
    Overview,
    Custodians,
    InterfacingWithBASElayer,
    EntryAndExit,
    CCCFramework,
    AggregatedPool,
    LegalEnforcement,
    LegalityOfSmartContracts,
    CCCRegulatoryCompliance,
    Summary,
    References,
  ];

  const buildComp = (text) => {
    return text.replace(/-|\s/g, "").charAt(0).toUpperCase() + text.slice(1);
  };

  return (
    <div className="doc-page-grid">
      <div className="docs-page-wrapper">
        <Switch>
          {pages.map((page, i) => {
            let MyComp = comps[i];
            return (
              <Route
                exact
                key={MyComp + i}
                path={page.link}
                render={(props) => {
                  setPageNumb(i);
                  return <MyComp {...props} />;
                }}
              />
            );
          })}
        </Switch>
        <div className="nav-wrap flex-align-cent flex-just-btw">
          {pageNumb > 0 ? (
            <Link to={`${pages[pageNumb - 1].link}`}>
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                />
              </svg>
              Previous Section
            </Link>
          ) : (
            <div />
          )}
          {pageNumb < pages.length - 1 ? (
            <Link to={`${pages[pageNumb + 1].link}`}>
              Next Section{" "}
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                />
              </svg>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="docs-toc">
        <div>
          {pages[pageNumb]
            ? pages[pageNumb].anch.map((anch) => (
                <NavHashLink
                  to={`#${anch}`}
                  activeStyle={{ borderLeft: "3px solid #1a73e8" }}
                >
                  {anch.replace(/-/g, " ")}
                </NavHashLink>
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

export default App;
