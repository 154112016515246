import React from "react";

const Overview = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Overview">Overview</h1>
      <span></span>
      <p>
        BASElayer’s on-chain platform is made possible by a robust custodial
        system. The aim of this system is to provide authenticity to the tokens
        on the platform and act as a gateway for assets to enter and exit the
        network. When combined with the on-chain component, the custodial system
        allows for the tokenization of anything from beanie babies™ to complex
        financial derivatives.{" "}
      </p>
      <p>
        The off-chain component of BASElayer is also intended to grow over the
        lifetime of the platform. Its modular design allows it to quickly scale
        inside whichever jurisdiction or municipality it is introduced.
        Furthermore, the process of adding custodians to the network is designed
        to be as simple and streamline as possible.{" "}
      </p>
      <p>
        In aggregate, BASElayer’s off-chain component is a scalable network of
        custodians, each operating independently of one another and each
        handling the financial instruments they are most comfortable handling.
        The result is an unparallel support system for all assets available on
        the BASElayer platform.
      </p>
    </div>
  );
};

export default Overview;
