import "./style.css";
import React, { useState } from "react";

const App = (props) => {
  return (
    <div className="action-card flex-col flex-align-cent">
      <div className="action-card-icon">{props.cont.icon}</div>
      <h5>{props.cont.title}</h5>
      <p>{props.cont.text}</p>
    </div>
  );
};

export default App;
