import React from "react";

const Interoperability = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Interoperability">INTEROPERABILITY</h1>
      <span></span>
      <p>
        Unlike traditional blockchain networks, BASElayer does not use a
        solitary chain. Instead, the platform is built upon an ever-growing
        number of interoperable chains. Even individual assets are capable of
        owning multiple blockchains, referred to as federations. These
        federations can communicate amongst one another but ultimately retain
        their independence. BASElayer does this for two primary reasons. First,
        processing assets independently of one another confines any issue to the
        chain that it originated on. Second, and most importantly, having
        multiple chains allows for the network to process transactions in
        parallel. This allows the network to quickly and easily scale to meet
        demand.{" "}
      </p>
      <p>
        The technology behind BASElayer’s cross-chain communication borrows
        heavily from the Komodo™ platform. Komodo is an industry leader in the
        field of blockchain scalability and its opensource solutions align
        perfectly with BASElayer’s goals. Combining this technology with the
        BASElayer architecture allows for a massive, fully interoperable
        ecosystem, with a multitude of chains simultaneously processing
        contracts and transactions. In other words, an incredibly fast,
        perfectly scalable marketplace.
      </p>
    </div>
  );
};
const NetworkwideMerkleRoot = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Networkwide-Merkle-Root">Networkwide Merkle Root</h1>
      <span>
        How collecting and redistributing a Merkle Root of all chains on the
        network facilitates interoperability
      </span>
      <p>
        So how are all these different blockchains able to communicate? Simply
        put, the network as a whole, collects and distributes a snapshot of all
        the various blockchains to all the blockchains on the platform. This
        snapshot is called a Merkle root (the culmination of a Merkle tree).
        Because each and every blockchain knows the state of each and every
        other blockchain, assets can be exchanged from one chain to another
        without compromising security. If a bad actor tries to trade an asset he
        does not own, the asset on the other side of the trade is capable of
        knowing that the trade is fraudulent.{" "}
      </p>
      <p>
        The networkwide snapshot is built by adding three additional layers to a
        block’s Merkle tree. As is the case with traditional blockchains, each
        block in a chain has a hash. This hash is a Merkle root built by hashing
        together all the transactions in the block. No transaction can be
        altered without significantly altering—and subsequently voiding—the
        hash.{" "}
      </p>
      <p>
        BASElayer takes this one step further by creating a hash of the entire
        blockchain. No block in the chain can be altered without changing the
        hash, effectively creating a snapshot of the blockchain in its entirety.
        But it doesn’t stop there. The hash of the chain is hashed together with
        all other blockchains related to the asset in question. This cluster of
        chains, supporting a specific asset, is referred to as a federation. The
        hash of this cluster is called the federation hash. It provides an
        immutable snapshot of all the blockchains supporting a particular asset.{" "}
      </p>
      <p>
        Finally, all the federation hashes are combined to make one final hash.
        This hash is the root of the network wide Merkle tree. It is a snapshot
        of every blockchain on the network’s state at a specific point in time.{" "}
      </p>
      <p>
        Because each and every block has already been verified by consensus, the
        networkwide Merkle root inherits this verification. It can be
        distributed freely to all the blockchains on the network allowing each
        and every chain to know, with absolute certainty, the state of each and
        every other chain.
      </p>
    </div>
  );
};
const FederatedChains = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Federated-Chains">Federated Chains</h1>
      <span>
        How BASElayer organizes the assets on the network and how they interact
        with one another
      </span>
      <p>
        Each asset on the BASElayer platform has its own blockchain—or cluster
        of chains. Whether it is a core currency or tokenized asset, all of a
        token’s transactions are processed on its own cluster, referred to as a
        federation. That is to say, an asset’s federation is the group of all
        the blockchains that process that asset’s transactions.{" "}
      </p>
      <p>
        For the most part, core currency and tokenized asset federations operate
        in much the same way. Tokens within a federation can move between chains
        using a simple burn protocol. If an address sends tokens to another
        address on a different chain within the federation, the token is
        destroyed (or burned) on the sender’s chain and created on the
        receiver’s chain. The process is known as an atomic swap.{" "}
      </p>
      <p>
        The only difference between core currency federations and other asset
        federations is that core currency chains can exchange tokens amongst any
        other core currency chain—even if they do not support the same currency.
        This is done through an exchange process involving an oracle and a smart
        contract. When a core currency exchange is initiated, the ownership of
        the sender’s tokens are relinquished to a smart contract. The contract
        queries an oracle to determine the value of the tokens denominated in
        the receiver’s currency. The sender’s tokens are subsequently burned,
        and the tokens of the equivalent value are minted on the receiver’s
        chain in the receiver’s currency. The newly minted tokens are then
        forwarded to the receiver’s address. In the event that a user is simply
        trying to convert tokens within his or her own address, the receiving
        address of the exchange process would be the same as the sending
        address. This is the same atomic swap protocol as before, only with an
        added step to discern the exchange rate.
      </p>
      <p>
        When trades are made between assets of two different types (outside of
        the core currencies), the atomic swap process requires a buyer and a
        seller. In other words, it is possible for a user to trade one asset for
        a different asset directly with another user. This does not require a
        3rd party exchange of any sort as it is entirely peer to peer. In the
        event two users enter into a multi asset trade, the buyer lists the
        parameters of the trade—constituting a bid—and sends the funds to a
        smart contract. This lets the seller review the trade before filling the
        order. If the trade is not filled, or there is an error, the funds are
        returned to both the buyer and the seller.
      </p>
      <h2 id="Order Routing">Order Routing </h2>
      <p>
        Bids are always known to the network. That is to say, if a user creates
        a bid for an asset, the network is aware of the potential trade. This
        makes routing complex orders through several trades possible. To do
        this, the network has a dedicated order routing protocol loosely based
        on the Interledger protocol™. For example, if a user would like to trade
        gold tokens for euros, but there is only a US Dollar token bid for gold
        tokens, the network can rout the order through the US Dollar token
        trade, perform a core currency atomic swap, and return the Euro tokens
        to the user. Thus, the order routing protocol creates liquidity in an
        otherwise stagnant market.
      </p>
      <h2 id="Nascent-Chain-Security">Nascent Chain Security </h2>
      <p>
        {" "}
        New blockchain projects are often at risk of succumbing to fifty one
        percent attacks. In such an attack, a malicious actor is able to acquire
        more than 50 percent of the networks processing power and in doing so
        break the security of the blockchain. As you might expect, 51 percent
        attacks are a serious problem for newer blockchains that do not yet have
        enough hash-power to prevent such attacks. This is not an issue on the
        BASElayer platform. New chains are supported by BASElayer nodes,
        inheriting the combined hash power of the entire network.
      </p>
    </div>
  );
};
const CrossChainAddresses = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Cross-Chain-Addresses">Cross-Chain Addresses</h1>
      <span>
        How a single address can be used to manage tokens across multiple asset
        federations
      </span>
      <p>
        Astute readers might have realized by this point that hashing the
        federations together into a networkwide Merkle root effectively creates
        a block of blocks (as opposed to a block of transactions). When these
        top-level blocks are ordered together it creates a blockchain of
        blockchains.{" "}
      </p>
      <p>
        It is on this top-level chain that addresses reside. Keeping addresses
        here allows users to access all assets created on the BASElayer platform
        from a single location. Furthermore, when new assets are introduced to
        the network, the ability to hold them is automatically added to all
        addresses on the network.{" "}
      </p>
      <p>
        When a new address is crated, it generates a sub-address on the newest
        chain of each federation. This process does not require any additional
        computing power as it is concurrently generated. If a new asset, and its
        accompanying blockchain, is created on the BASElayer platform, it is
        automatically endowed with the sub-addresses of all previously existing
        addresses. If the asset in question receives enough traffic to require a
        second chain within its federation, then all subsequently generated
        addresses will generate sub-addresses on the second, newest, chain.
      </p>
    </div>
  );
};
const HorizontalScalability = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Horizontal-Scalability">Horizontal Scalability</h1>
      <span>
        How adding chains allows a network to process transactions in parallel
      </span>
      <p>
        The term scalability is often confused with speed. As we saw in the
        consensus section of this paper, BASElayer facilitates lightning fast, 3
        to 5 second transactions. However, the network would be impractical if
        it could not retain this speed during heavy workloads. This is where
        scalability plays an important role.{" "}
      </p>
      <p>
        BASElayer uses federated chains so that nodes can process transactions
        in parallel. This means that under heavy load, a bottleneck in the
        consensus process can be easily circumvented. If the number of pending
        transactions for the consensus process grows two large, a second chain,
        with its own consensus process can be created. Furthermore, a federation
        is capable of managing its own scaling. If a second chain is not enough
        to compensate for an increase in traffic, then a third chain can be
        created at no expense to the network (or its performance). This process
        can be repeated as many times as necessary, and results in the network
        growing horizontally.{" "}
      </p>
      <p>
        Note that scalability does not come at a cost to security. Verification
        is done on the blockchain level and is inherited by a federation.
        Furthermore, the networkwide hash is distributed amongst all nodes in
        what is known as a network wide sync. A sync occurs once every minute,
        meaning that all nodes know the state of every blockchain at one-minute
        intervals. It is not necessary for the sync to occur as fast as
        transactions because it serves as a starting point for calculating
        validity of an exchange rather than information the check validity
        against. Think of the most recent sync as the most recent answer to a
        long math problem that a node can then use to calculate a new math
        problem.{" "}
      </p>
      <p>
        When you combine the syncing process with asset federations and cross
        chain addresses, you get an incredibly efficient scaling solution. This
        allows BASElayer to provide fast transaction times no matter how busy
        the network becomes.
      </p>
    </div>
  );
};

export {
  Interoperability,
  NetworkwideMerkleRoot,
  FederatedChains,
  CrossChainAddresses,
  HorizontalScalability,
};
