import React from "react";

import Landing from "../../comps/Landing";
import Content from "../../sections/Content";
import About from "../../sections/About";
import Cards from "../../sections/Cards";

import Tile from "../../media/tile.svg";
import Img1 from "../../media/Img1.png";
import Img2 from "../../media/Img2.png";
import Img3 from "../../media/Img3.png";

const App = () => {
  const content = [
    {
      direction: 0,
      subtitle: "TOKENIZED ASSETS",
      title: "Real Digital Money",
      text:
        "BASElayer supports five core currencies by default. All five are exchangeable, absolvable, and backed at any given time.",
      button: "More About Tokens",
      link: "/Docs/Assets/Tokenized-Assets",
      img: Img1,
    },
    {
      direction: -1,
      subtitle: "SMART CONTRACTS",
      title: "Fully Programmable in Any Language",
      text:
        "BASElayer harnesses the power of Smart Contracts to make all assets ‘programmable.’ These contracts are Turing complete, language agnostic, and compatible with any asset",
      button: "More About Contracts",
      link: "/Docs/Virtual-Machine/Smart-Contracts",
      img: Img2,
    },
    {
      direction: 0,
      subtitle: "VIRTUAL MACHINE",
      title: "An Immutable, Scalable Runtime Environment",
      text:
        "BASElayer offers developers free access to a powerful virtual machine. Due to its unique design bottlenecks and long processing ques are a thing of the past.",
      button: "More About the VM",
      link: "/Docs/Virtual-Machine/Runtime-Environment",
      img: Img3,
    },
  ];

  return (
    <React.Fragment>
      <Landing />
      <section>
        <Cards />
        <div className="content-wrapper">
          {content.map((cont) => (
            <Content cont={cont} />
          ))}
          <About />
          <a className="doc-button felx-align-cent flex-just-btw">
            <p>Read Documentation</p>
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
              />
            </svg>
          </a>
        </div>
      </section>
    </React.Fragment>
  );
};

export default App;

// <Landing />
// <img src={Tile} />
