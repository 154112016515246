import React from "react";

const App = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Getting-Started">Getting Started</h1>
      <p>
        The future of digital payment is perfect compatibility, where all
        vehicles of value—currencies, equities, derivatives—are interoperable.
        BASElayer is the ultimate platform for facilitating this
        interoperability and developing the financial applications that will
        utilize it. BASElayer’s open codebase, language agnosticism, and
        scalable runtime environment grant developers end-to-end, monetizable
        control of their creations. These unique characteristics are made
        possible by a distributed ledger network paired with a robust custodial
        system. What follows is an in-depth explanation of these on-chain and
        off-chain components and how they work to support the BASElayer
        platform.
      </p>
    </div>
  );
};

export default App;

//</p><p>
//<br/>
