import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const App = (props) => {
  const [open, setOpen] = useState(false);
  const section = props.section;
  return (
    <div className={`nav-sec-wrap ${open ? "nav-sec-open" : ""}`}>
      <div className=" flex-align-cent flex-just-btw">
        <NavLink
          exact={section.link === "/Docs" ? true : false}
          to={section.link}
          onClick=""
          className="docs-link"
          activeStyle={{
            color: "#1a73e8",
          }}
          onClick={(e) => section.subs && setOpen(true)}
        >
          {section.title}
        </NavLink>
        {!section.subs ? null : (
          <button
            className={open ? "nav-up" : ""}
            onClick={(e) => setOpen(!open)}
          >
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
              />
            </svg>
          </button>
        )}
      </div>
      {!section.subs || !open
        ? ""
        : section.subs.map((sub) => (
            <NavLink
              exact
              to={sub.link}
              className="docs-link docs-link-sub"
              activeStyle={{
                color: "#1a73e8",
              }}
            >
              {sub.title}
            </NavLink>
          ))}
    </div>
  );
};

export default App;
