import React from "react";

const AddressTypes = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Addresses">ADDRESSES</h1>
      <span></span>
      <p>
        To access the BASElayer network, participants are required to have a
        unique address or set of addresses. An address—sometimes referred to as
        an account—is denominated by a 25 to 35 case sensitive, alphanumeric
        code. This code is what is known as a public Key. It is the account
        holder’s location on the network and is analogous to an email address.{" "}
      </p>
      <p>
        Addresses are capable of holding any token issued on the BASElayer
        network. One address, for example, can hold USD, EUR, and even a
        Commodity Backed Token (if one were issued). This allows for aggregation
        of a user’s funds and makes managing accounts across different asset
        classes much simpler than traditional systems. Conversely, a user may
        choose to obtain several addresses and organize assets among them.
        Because addresses are token agnostic, users are free to use them as they
        see fit.{" "}
      </p>
      <p>
        There are three main types of network participants on the BASElayer
        platform: Users, Custodians, and Contracts. Each participant uses an
        address that has been customized through PKI signatures. While all three
        address types adhere to the public private key architecture, each
        address has its own unique set of network privileges.
      </p>
    </div>
  );
};
const BasicAddress = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Basic-Address">Basic Address</h1>
      <span>The attributes and privileges of a user address</span>
      <p>
        A Basic Address is the most rudimentary address type on the BASElayer
        platform. Basic addresses can send and receive tokens on the BASElayer
        network. In addition to the core currency tokens, basic addresses can
        hold any type of token on the network. <p></p>
        These addresses are available to anyone and can be generated freely. A
        basic address does not require any permission to obtain or create.{" "}
        <p></p>
        The basic address should be seen as the fundamental component for a
        user’s account on the BASElayer network. As we will discuss further in
        this section, it can be paired with signatures and contracts to grant
        the address specific features. A good analogy would be to think of the
        basic address as a piece of clay than can be molded into a more specific
        final form.
      </p>
    </div>
  );
};
const CustodialAddress = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Custodial-Address">Custodial Address</h1>
      <span>The attributes and privileges of a Custodial address</span>
      <p>
        Custodial addresses are a specific type of address built using a basic
        address and signatures. While, technically, they are modified Basic
        Addresses, they are an integral part of the BASElayer platform and are
        referred to as their own address type.{" "}
      </p>
      <p>
        Custodial Addresses are granted to custodians on the network. They are
        the bridge between the digital platform and the physical world. While
        they have all the functionality of a basic address, their primary
        purpose is to facilitate withdraws from and deposits into the network.{" "}
      </p>
      <p>
        Let’s take a look at how a deposit works. When a custodian initiates a
        deposit, the Custodial API , or CAPI, communicates the deposit request
        to a mint contract via the custodian’s address. This request must be
        made using an authorized custodian’s address otherwise it will be
        denied. The mint contract checks the requests for authorization then
        completes the request using the supplied information.{" "}
      </p>
      <p>
        Conversely, when a withdraw is requested, the tokens, as well as the
        withdraw information, are sent to a withdraw contract. The contract
        burns the tokens and routs the request to the correct custodial address.
        From here the information is picked up by the CAPI and communicated to
        the custodian.{" "}
      </p>
      <p>
        To better understand this process, we can use an example. Alice would
        like to fund her address on the network. She makes a deposit in US
        dollars at her local bank which is a custodian on the BASElayer
        platform. The bank enters the deposit into its computer system. Through
        the CAPI, the bank’s custodial address receives the request and notifies
        a USD Mint Smart Contract on the network, providing Alice’s account,
        address, and deposit information. The digital USD tokens are minted and
        forwarded to Alice’s BASElayer address. As long as the token exists, the
        underlying USD funds continue to be held by the custodian. Only if the
        token (or any successor token) is redeemed for USD cash, can the
        custodian relay the funds.{" "}
      </p>
      <p>
        Another important stipulation is that the custodial addresses are
        granted custodial status using signatures. This meaning that a custodian
        retains ownership—and therefore full control—over their addresses and no
        BASElayer core involvement is required. Furthermore, in term of
        mechanics, custodians don’t mint their own tokens, they query a mint
        contract. This is primarily to make amendments/ upgrades to the BL20
        standards easier. Instead of requiring every custodian on the network to
        upgrade to new standards, amendments can simply be made once to the mint
        contracts.{" "}
      </p>
      <p>
        It is important to note that custodial addresses cannot be generated as
        freely as basic addresses. They are (or rather the required signatures
        are) issued by the creator of a mint contract. in the case of Core
        Currency Custodians (CCC) the necessary signatures are obtained through
        an application process and are administered by the BASElayer Core.{" "}
      </p>
      <p>
        Custodians act as the entry and exit point of the core currencies on the
        network and are therefore subjected to both BASElayer’s rules and any
        legal or banking regulations that apply. Because custodians have the
        ability to ‘create money’ it is important that applicants be thoroughly
        vetted. For more information on custodians please see the off-chain
        section of this paper.
      </p>
      <h2 id="Third-party-Asset-Custodians-(TPC)">
        Third-party Asset Custodians (TPC){" "}
      </h2>{" "}
      <p>
        Developers of custom tokens can use their own signature infrastructure
        to manage their own custodians. An example of this would be an issuer of
        a gold token (representing an ounce of gold) issuing a custodial address
        to a holding house. The relationship would be maintained by only the
        parties involved. Furthermore, through signatures, the custodial status
        issued by the gold token creator is confined to the gold token. The
        custodian cannot act as a custodian for any other tokens on the network
        unless granted custodial status by the respective token’s issuer. The
        result is an autonomous custodial subnetwork.{" "}
      </p>
    </div>
  );
};
const ContractAddress = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Contract-Address">Contract Address</h1>
      <span>The attributes and privileges of a Custodial address</span>
      <p>
        Smart contracts claim funds through signing UTXOs, however, contracts
        can transfer the UTXOs they own to a basic address. Aggregating these
        funds into a single address makes it easy to discern which funds the
        contract is in control of. While technically the contract owns the UTXOs
        in the address, and not the address itself, it can be easier to think of
        the address as “owned” by the contract, effectively replacing the
        addresses private key with the clause of the contract. This renders the
        existing private key useless as the funds in the address can only be
        managed by the contract. Consequently, it is easier to think of basic
        addresses holding funds controlled by a smart contract as their own
        separate type of addresses: contract addresses.{" "}
      </p>
      <p>
        Contract addresses make up the bulk of the addresses found on the
        BASElayer platform. Through contracts and signatures developers can add
        features and privileges to any address, making it more secure, user
        friendly, and/or regulatory-compliant.{" "}
      </p>
      <p>
        A great example of this would be an account at an online bank created on
        the BASElayer platform.{" "}
      </p>
      <p>
        The issuer of the account could grant its signature to any users that
        comply with Know Your Customer (KYC) requirements. This signature could
        be a requirement for sending and receiving funds to and from the
        account. furthermore, the address could be paired through multisig
        requirements with a fraud prevention AI. The result would be a
        regulatory-compliant, multi-asset bank account with security features
        that rival traditional banks at a fraction of the cost. Furthermore, the
        user would still retain control of all the assets they own.{" "}
      </p>
      <p>
        For more information on what smart contracts can do please see the smart
        contracts section of this paper.
      </p>
    </div>
  );
};

export { AddressTypes, BasicAddress, CustodialAddress, ContractAddress };
