import React from "react";

const Signatures = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="signatures">SIGNATURES</h1>
      <span></span>
      <p>
        The BASElayer platform uses a robust signature system. Signatures allow
        users to grant addresses, smart contracts, and UTXOs different
        privileges. Consequently, ownership and authority structures can be
        created to meet the needs of different applications. For those readers
        familiar with network architecture, the system is similiter to how a
        Public Key Infrastructure (PKI) works. BASElayer’s signature
        infrastructure is what allows users to issue coexisting authority tiers
        without compromising the platform’s security.
      </p>
    </div>
  );
};
const SignaturesStructure = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Signatures-Structure">Signatures Structure</h1>
      <span>The pointer and certificate components of a signature </span>
      <p>
        All addresses on the BASElayer network have a private and public key. It
        might be easier, however, to think of private key as a signing key and
        the public key as a verification key. Through the mathematic principals
        of cryptography, the verification key can be derived from the signing
        key, but the signing key cannot be derived from the verification key.{" "}
      </p>
      <p>
        If a user would like to send a message, he or she can apply a
        mathematical transformation, known as a hash, to the message using the
        signing key. This creates a signature specific to that message . To
        check the authenticity of the message, a reader can take the message,
        the signature, and the verification key, and mathematically determine
        its validity.{" "}
      </p>
      <p>
        For BASElayer signatures, the message is a pointer (directions) to the
        conditions (contract) of the signature. Through this mechanism, a
        signature relating to a complex set of conditions can be granted in a
        lightweight, easy to verify format.{" "}
      </p>
      <p>
        Because contracts are validated through consensus and immutable after
        they have been entered into their respective blockchain, nodes do not
        need to perform any sort of additional validation when retrieving the
        contract via the pointer. Validation is inherited as long as the pointer
        references a location validated in the last networkwide sync.{" "}
      </p>
      <h2 id="Addresses">Addresses</h2>{" "}
      <p>
        Any one address can sign over specified privileges to any other address.
        Technically the address itself does not have a signature residing on it,
        rather a signature is sent to it. The immutability of the network
        ensures that the sent signature can never be revoked, unless a
        cancelation is sent by the issuer. To keep things simple, as long as a
        signature message has been sent and a cancelation message has not been,
        the signature is active, and the address is considered signed. To make
        the state of a signature easier to discern, signatures are granted their
        own section I the block structure.{" "}
      </p>
      <h2 id="Contracts">Contracts</h2>{" "}
      <p>
        A contract’s signatures—signatures on a contract and not ones issued by
        the contract—are stored as a sub-condition, creating a Boolean circuit.
        This might sound complicated but all it means is that before a
        contract’s processes are run; a list of signatures is scanned to see it
        the conditions for running the contract are met. If they are, the
        contract is allowed to be processed. If the conditions are not met, then
        the contract is not processed. You can read more about this principal
        here.{" "}
      </p>
      <h2 id="UTXOs">UTXOs</h2>
      <p>
        UTXOs (funds) can also be signed by addresses and contracts. These types
        of signatures do not follow quite the same signature structure, but
        because they serve the same purpose, we will treat them, for now, as
        common signatures. You can read more on how smart contracts work here.{" "}
      </p>
    </div>
  );
};
const AuthoritySubstructures = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Authority-Substructures">Authority Substructures</h1>
      <span>
        What a Signature Authority (SA) is and how it operates on the BASElayer
        platform
      </span>
      <p>
        Traditionally, when a network needs to administer privileges to
        different users, those privileges are administered by a network
        authority. In a decentralized system like BASElayer, there is no one
        authority. In fact, any network participant can create its own
        substructure of authorities using signatures. In this case, the issuer
        of the privileges (by way of signature) is the signature authority (SA).{" "}
      </p>
      <p>
        Signature authority structures tend to form around asset issuance. For
        example, BASElayer Core is the SA for the network’s core currencies. It
        can issue signatures granting third parties privileges relating to the
        creation and handling of the core currencies. That does not mean the
        network is centralized. Transactions, consensus, contracts and other
        assets cannot be interfered with. BASElayer is only capable of managing
        permissions involving core currencies. This is exclusively done so that
        BASElayer can manage the core currency custodians, mint contracts, and
        trusted nodes who wish to join the network. For a network dealing in
        regulated assets, this is a requirement.{" "}
      </p>
      <p>
        Any network participant can deploy their own signature substructure,
        acting as its SA. One great example would be a notary signature
        structure. let’s say you have an established ratings agency on the
        BASElayer platform. Its business model is to review tokenized assets and
        notarize tokens that meet its standards and who pay a small fee. The
        business model is a successful one and a smaller ratings agency wishes
        to partner with them. The established agency can issue signature
        substructure allowing its new partner to issue the same notarization
        while remaining an independent entity. The established agency is the SA
        of this substructure and can withdraw the this right at any time.{" "}
      </p>
      <p>
        While this is just one example, signature substructures can be applied
        in many other ways (custodianship, insurance, licenses, etc.).
        Signatures allow for network participants to grant unique privileges,
        access, or functionality to other network participants within their own
        substructure.
      </p>
    </div>
  );
};

export { Signatures, SignaturesStructure, AuthoritySubstructures };
