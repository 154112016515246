import "./style.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Space from "../../media/space.svg";

const App = () => {
  return (
    <section className="landing-wrap">
      <div className="flex-align-cent flex-col">
        <div className="landign-cont-wrap flex-just-btw flex-align-cent">
          <div className="landing-text-wrap">
            <h2>A Base Layer for Financial Apps</h2>
            <h1>
              BASElayer is a blockchain Platform for Creating, Managing, and
              Interacting with Tokenized Assetss.
            </h1>
            <div className="flex-align-cent landing-button-wrap">
              <Link to="/Docs">
                <button>View Docs</button>
              </Link>
              <a href="mailto:matt@baselayer.io">
                <button className="hide-landng-button">Contact us</button>
              </a>
              <div
                className="hide-landng-button"
                style={{
                  height: "32px",
                  borderLeft: "2px solid rgba(255,255,255,.3)",
                }}
              />
              <HashLink to="#quote" smooth>
                <button className="hide-landng-button">More</button>
              </HashLink>
            </div>
          </div>
          <div className="video-wrap">
            <div className="video-cont">
              <iframe
                allowtransparency="true"
                src="https://www.youtube.com/embed/Mwf-rLZqPzo"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <svg className="quotes quote-1" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z"
            />
          </svg>
          <svg className="quotes quote-2" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z"
            />
          </svg>

          <h3 id="quote">
            <b>All vehicles of value</b> — currencies, <br />
            equities, derivatives — <b>should be interoperable.</b>
          </h3>
        </div>
      </div>
    </section>
  );
};

export default App;
