import React, { useState } from "react";
import "./style.css";
import Card from "../../comps/ActionCards";

const App = (props) => {
  const endless = (
    <svg viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z"
      />
    </svg>
  );
  const fast = (
    <svg viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M15,4A8,8 0 0,1 23,12A8,8 0 0,1 15,20A8,8 0 0,1 7,12A8,8 0 0,1 15,4M15,6A6,6 0 0,0 9,12A6,6 0 0,0 15,18A6,6 0 0,0 21,12A6,6 0 0,0 15,6M14,8H15.5V11.78L17.83,14.11L16.77,15.17L14,12.4V8M2,18A1,1 0 0,1 1,17A1,1 0 0,1 2,16H5.83C6.14,16.71 6.54,17.38 7,18H2M3,13A1,1 0 0,1 2,12A1,1 0 0,1 3,11H5.05L5,12L5.05,13H3M4,8A1,1 0 0,1 3,7A1,1 0 0,1 4,6H7C6.54,6.62 6.14,7.29 5.83,8H4Z"
      />
    </svg>
  );
  const scale = (
    <svg viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M8.46,15.06L7.05,16.47L5.68,15.1C4.82,16.21 4.24,17.54 4.06,19H6V21H2V20C2,15.16 5.44,11.13 10,10.2V8.2L2,5V3H22V5L14,8.2V10.2C18.56,11.13 22,15.16 22,20V21H18V19H19.94C19.76,17.54 19.18,16.21 18.32,15.1L16.95,16.47L15.54,15.06L16.91,13.68C15.8,12.82 14.46,12.24 13,12.06V14H11V12.06C9.54,12.24 8.2,12.82 7.09,13.68L8.46,15.06M12,18A2,2 0 0,1 14,20A2,2 0 0,1 12,22C11.68,22 11.38,21.93 11.12,21.79L7.27,20L11.12,18.21C11.38,18.07 11.68,18 12,18Z"
      />
    </svg>
  );

  const arr = [
    {
      icon: endless,
      title: "ENDLESS ASSETS",
      text: "The Ability to Tokenize and Trade Anything",
      link: "ttext",
    },
    {
      icon: fast,
      title: "LIGHTNING FAST",
      text: "3-5 Second Transactions and Settlement",
      link: "ttext",
    },
    {
      icon: scale,
      title: "INFINITLY SCALABLE",
      text: "Limitless Scalability with 40k tps Already Proven",
      link: "ttext",
    },
  ];

  return (
    <div className="action-cards ">
      <div className="flex-align-cent flex-just-cent action-cards-wrap">
        {arr.map((cont) => (
          <Card cont={cont} />
        ))}
      </div>
      <svg
        className="topper-svg"
        viewBox="0 0 838 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 68C0 47.4782 15.2547 30.1037 35.6135 27.5229C330.173 -9.81596 506.571 -8.58 802.367 27.6849C822.71 30.179 838 47.5039 838 68V68H0V68Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default App;
