import React from "react";

const AggregatedPool = () => {
  return (
    <div className="text-elm-wrap">
      <h1 id="Aggregated-Pool">AGGREGATED POOL</h1>
      <span></span>
      <p>
        Trading is a zero-sum transaction. For every purchase there is an equal
        and opposite sale. Consequently, if a custodian holds both sides of a
        trade, it never needs to adjust its balance. This is the premise behind
        BASElayer’s custodial network. In aggregate, the pool of real-world
        currencies held in custody matches the pool of core currencies tokens.
        Even through there are many trades occurring on the network, the pool
        only ever needs to adjust slightly.{" "}
      </p>
      <p>
        Note the use of the term slightly. In theory, there would never need to
        be any adjustment. However, BASElayer allows for core currency tokens to
        be exchanged for other core currency tokens at the listed exchange rate.
        This ever so slightly breaks the parity between the currencies entering
        and exiting the network.{" "}
      </p>
      <p>
        Because BASElayer collects fees in token from, it has an ever-growing
        buffer of funds which it retains ownership of. This buffer can be dipped
        into to readjust the core currency holdings so that they match the core
        currency tokens on the network. Note that this balancing act is an
        accounting principal and does not require physical funds to be moved.{" "}
      </p>
      <p>
        However, in the event that the allocation of core currency tokens shifts
        with greater severity than the buffer can accommodate, BASElayer will
        use forex markets to bring the core currency funding back into balance.{" "}
      </p>
      <p>
        Rebalance of the CC Pool occurs once a month barring any drastic
        changes.
      </p>
    </div>
  );
};

export default AggregatedPool;
